<template>
  <loader
    :object="styling.objectcolor"
    :color1="styling.animationcolor"
    :color2="styling.animationcolor2"
    :size="styling.size"
    :speed="styling.speed"
    :bg="styling.bgcolor"
    :objectbg="styling.objectbg"
    :opacity="styling.opacity"
    :name="selected"
    :disableScrolling="styling.disableScrolling"
  ></loader>
  <div class="fixed-top">
    <Header />
    <div id="tophead" v-if="showheadDiv">
      <div class="header-bot-sec">
        <div class="container">
          <div class="row">
            <div class="rdo">
              <form action="#">
                <p class="first-rasecc">
                  <input
                    type="radio"
                    id="quote"
                    name="radio-group"
                    :checked="isQuote"
                    disabled
                  />
                  <label for="quote" v-bind:class="{ active: isQuote }"
                    ><span>QUOTE</span></label
                  >
                </p>
                <p class="first-rasecc1">
                  <input
                    type="radio"
                    id="userinfo"
                    name="radio-group"
                    :checked="isinfo"
                    disabled
                  />
                  <label for="userinfo" v-bind:class="{ active: isinfo }"
                    ><span>YOUR INFO</span></label
                  >
                </p>
                <p class="first-rasecc2">
                  <input
                    type="radio"
                    id="payment"
                    name="radio-group"
                    :checked="ispayment"
                    disabled
                  />
                  <label for="payment" v-bind:class="{ active: ispayment }"
                    ><span>PAYMENT</span></label
                  >
                </p>
                <p class="first-rasecc3">
                  <input
                    type="radio"
                    id="wellcome"
                    name="radio-group"
                    disabled
                  />
                  <label for="wellcome"><span>WELCOME</span></label>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="Monthly-Head">
        <div class="container">
          <div class="row">
            <p>
              Monthly Quote Total:
              <span class="monthly-quote-amount">${{ QuoteTotal.toFixed(2) }}</span>
              &nbsp;<span>(taxes extra)</span>
            </p>
          </div>
        </div>
      </div>-->
    </div>
  </div>
  <slot />
  <div id="app">
    <form>
      <aside class="bg2">
        <div class="trf p-3">
          <h4 class="dfdd">
            <img class="imggf" src="..//assets/Calendar-30day.svg" alt="" />
            <strong> 30-Day Free-Look Guarantee</strong>
          </h4>
          <div class="pdss">
            <p class="">
              <strong>Enroll with confidence.</strong> Review your policy for 30
              days, and if you are not completely satisfied we’ll refund your
              first month’s premium. Some restrictions apply.
              <span>
                See
                <a
                  class="text-dark text-reset"
                  href="https://www.phidirect.com/hubfs/8863602/PHI-Direct-Documents/PHI-CA-Policy-Document-Online.pdf#page=23"
                  target="_blank"
                >
                  <u><strong>Policy</strong></u>
                </a>
                for details.</span
              >
            </p>
          </div>
        </div>
      </aside>
      <div class="container Payment-Method-Section">
        <div class=""></div>
        <div class="row">
          <div class="Payment-Summary-Main">
            <h2>Payment Summary</h2>
            <div
              class="row BigglesWorth-Sec"
              v-for="(item, key) in petsInfo"
              :key="key"
            >
              <div class="col-md-5">
                <h3>
                  <span>For</span>
                  {{ item.petInfo != null ? item.petInfo.petName : "" }}
                </h3>
              </div>
              <div class="col-md-7">
                <div
                  class="row"
                  v-for="(policyitem, key) in item.policyList"
                  :key="key"
                >
                  <div class="col-md-12" v-if="policyitem.selected == true">
                    <div class="Direct-Annual">
                      <p>{{ policyitem.productName }} /</p>
                      <p>
                        ${{
                          policyitem.productName === "Direct 5"
                            ? "5,000"
                            : "10,000"
                        }}
                        ANNUAL BENEFITS
                      </p>
                      <p>
                        <span>
                          ${{
                            policyitem.productName === "Direct 5"
                              ? "200"
                              : "200"
                          }}
                          deductible / 80% reimbursement
                        </span>
                      </p>
                    </div>
                    <div
                      class="Direct-Annual1"
                      v-for="(tax, key) in policyitem.tax"
                      :key="key"
                    >
                      <p>
                        Monthly Premium
                        <span>${{ policyitem.firstMonthly.toFixed(2) }}</span>
                      </p>
                      <p>
                        Taxes
                        <span>${{ (feeName*policyitem.enrollmentFees[0].feeTax/100).toFixed(2) }}</span>
                        
                         
                      </p>
                    </div>
                    <div
                      class="Direct-Annual2"
                      v-for="(tax, key) in policyitem.tax"
                      :key="key"
                    >
                      <p>
                        Total Monthly Cost
                        <span
                          >${{
                            (
                              Number(policyitem.firstMonthly.toFixed(2)) +
                              Number((feeName*policyitem.enrollmentFees[0].feeTax/100).toFixed(2))
                            ).toFixed(2)
                          }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-7">
                <div class="Total-Monthly">
                  <h4>
                    Total Monthly Premium 
                    <span>${{ (Number(TotalMonthlyPremium.toFixed(2)))}}</span>
                  </h4>
                  <p class="drd">
                    Payments will be withdrawn on the
                    <b>{{ currentDay }}{{ nthDate }}</b> of each month using
                    your payment method selected below.
                  </p>
                </div>
              </div>
              <div class="row Total-inner">
                <div class="col-md-5"></div>
                <div class="col-md-7"></div>
              </div>
            </div>
            <div class="row Today-Payment">
              <div class="col-md-5">
                <h3>Today's Payment Summary</h3>
              </div>
              <div class="col-md-7">
                <div v-for="(item, key) in petsInfo" :key="key">
                  <p>
                    {{
                      item.petInfo != null
                        ? item.petInfo.petName + "- First Month"
                        : ""
                    }}
                    <span
                      v-for="(policyitem, key) in item.policyList"
                      :key="key"
                    >
                      <div v-if="policyitem.selected == true">
                        <span v-for="(tax, key) in policyitem.enrollmentFees" :key="key"
                          >${{
                            (
                              Number(policyitem.firstMonthly.toFixed(2)) +                            

                              Number((feeName*tax.feeTax/100).toFixed(2))
                            ).toFixed(2)
                          }}</span
                        >
                      </div>
                    </span>
                  </p>
                </div>

               <!--for stroke lines in One-Time Enrollment Fee <p class="toolki">-->
                 <p>
                  One-Time Enrollment Fee
                  <!-- <span class="popup" @mouseover="myHoverFunction()"><img src="../assets/tooltipIcon.png"
                      class="img-responsive popup" />
                    <span class="popuptext" id="myPopup">We make this charge only once per pet and it helps keep
                      all your future costs lower.</span>
                  </span> -->
                  <!-- <span>${{ feeName.toFixed(2) }} </span> -->
                 <!--  <span>${{ (35.0 * PetCount).toFixed(2) }}</span>-->
                 <span>${{ feeName.toFixed(2) }}</span>
                </p>
                <!--<p class="addl">
                  ${{ (35.0 * PetCount).toFixed(2) }} enrollment fee waived
                  today!
                </p>-->

                <!-- <p>
                  Enrollment Fee Tax
                  <span>${{ feeTax.toFixed(2) }}</span>
                </p> -->

               <p><strong>Local taxes may apply to enrollment fees</strong></p>
                <div class="Total-Monthlyp">
                  <h4>
                    Today's Total:
                    <!-- <span id="totalAmount"
                      >${{
                        (
                          Number(TotalMonthlyPremium.toFixed(2)) +
                          Number(feeName.toFixed(2)) +                       
                           Number((feeName*feeTax/100).toFixed(2))
                        ).toFixed(2)
                      }}</span -->
                    <span id="totalAmount"
                      >${{
                        (
                        Number(TotalMonthlyPremium.toFixed(2))+Number(feeName.toFixed(2))
                        ).toFixed(2)
                      }}</span
                    >
                  </h4>
                </div>

   
                
             
           
                
              </div>
            </div>
          </div>
          <div class="Payment-Method-Main">
            <h2>Payment Method</h2>
            <p>Choose how you'd like to pay your monthly premium.</p>
            <div class="row">
              <div class="col-md-3">
                <!-- <label>Choose Method</label> -->
              </div>
              <div class="col-md-5 Choose-MethodSec">
                <select
                  v-model="selectPayment"
                  class="form-control"
                  @change="onChange($event)"
                  id="exampleFormControlSelect1"
                >
                  <!-- <option>--- Select Payment Method ---</option> -->
                  <option value="Card">Credit Card</option>
                  <option value="Paypal">PayPal</option>
                  <option value="Apple">Apple Pay</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div v-if="showForm == 'Card'">
      <div
        class="col-12 col-md-8 mx-auto card-section my-2 Payment_InformationsWidhSec"
      >
        <div class="card bg-light">
          <div class="card-header">Payment Information</div>
          <div class="card-body">
            <form v-show="hostedFieldInstance">
              <div class="form-group">
                <label class="line" v-if="collectCardHolderName"
                  >Cardholder Name</label
                >
                <input
                  type="text"
                  class="form-control Input-FieldCH"
                  id="cardholder"
                  name="cardholder"
                  placeholder="Name"
                  autocomplete="false"
                  style="color: green"
                />
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label>Card Number</label>
                    <div
                      id="creditCardNumber"
                      class="form-control Input-FieldCH"
                    ></div>
                  </div>
                  <div class="col-12 col-md-6">
                    <label for="postal">Postal Code</label>
                    <!-- <input
                      type="text"
                      class="form-control Input-FieldCH"
                      @input="upper($event)"
                      pattern="^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$"
                      autocomplete="false"
                      id="postal"
                      name="postal"
                      placeholder="A1A 1A1"
                      style="color: green"
                      v-model="quote.postalCode"
                      required
                    /> -->
                    <vue-mask
                      class="form-control Input-FieldCH"
                      v-model="quote.postalCode"
                      v-on:change="isPostalCodeValid"
                      id="postal"
                      name="postal"
                      placeholder="A1A 1A1"
                      @input="upper($event)"
                      mask="AAA AAA"
                      style="color: green"
                      pattern="^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$"
                      :options="options"
                    >
                    </vue-mask>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label>Expiration Date</label>
                    <div
                      id="expireDate"
                      class="form-control Input-FieldCH"
                    ></div>
                  </div>
                  <div class="col-12 col-md-6">
                    <label>CVV</label>
                    <div id="cvv" class="form-control Input-FieldCH"></div>
                  </div>
                </div>
              </div>
              <div style="display: none" id="paypalButton"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showForm == 'Paypal'">
      <dropin wrapperClass="constrain"></dropin>
    </div>
    <div v-if="showForm == 'Apple'">
      <applePay wrapperClass="constrain"></applePay>
    </div>
    <!-- <div class="Payment-Method-Main MainWidthSection">
      <h2>Direct Deposit</h2>
      <p>
        We use Direct Deposit to reimburse claims. This ensures your claims
        reimbursement gets to you quickly by depositing directly into your bank
        account.
      </p>
      <p>Would you like to provide your account information now?*</p>
      <div class="CheckBox-Cir payment-Checksec">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            name="learnGroup"
            type="radio"
            value="1"
            @change="ShowHideDiv()"
          />
          <label class="form-check-label">Yes</label>
          <input
            class="form-check-input"
            name="learnGroup"
            type="radio"
            value="2"
            @change="HideDiv()"
          />
          <label class="form-check-label">No</label>
        </div>
      </div>
      <div
        class="InnerForm-Methodcard Thank-Text"
        id="dvtext"
        style="display: none"
      >
        <div class="row">
          <div class="col-md-12">
            <div class="InnerForm-Methodcard">
              <p>
                Thank you. While not necessary to provide us with your banking
                information now, we will require it prior to reimbursing any
                claim that you may submit.
              </p>
              <p>
                When convenient, you can call our Care Team at 1-855-600-7072 to
                provide your banking information.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="chkYes" style="display: none">
        <div class="row">
          <div class="col-md-4">
            <label>Account Holder Name*</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control Input-FieldCH"
              placeholder=""
              v-model="quote.accountHolder"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Branch/Transit Number*</label>
          </div>
          <div class="col-md-3">
            <input
              type="text"
              class="form-control Input-FieldCH"
              placeholder=""
              v-model="quote.transitNumber"
              maxlength="5"
              minlength="5"
              @keypress="isNumber($event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Bank/Institution Number*</label>
          </div>
          <div class="col-md-2">
            <input
              type="text"
              class="form-control Input-FieldCH"
              placeholder=""
              v-model="quote.institutionNumber"
              maxlength="3"
              minlength="3"
              @keypress="isNumber($event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Account Number*</label>
          </div>
          <div class="col-md-4">
            <input
              type="text"
              class="form-control Input-FieldCH"
              placeholder=""
              v-model="quote.accountNumber"
              maxlength="12"
              minlength="12"
              @keypress="isNumber($event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Use a Cheque to locate your banking information</label>
          </div>
          <div class="col-md-6">
            <input
              style="display: none"
              type="text"
              class="form-control Input-FieldCH"
              placeholder=""
              v-model="quote.checkInfo"
            />
            <img alt="Vue logo" src="../assets/cheque.png" class="img-fluid" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="Payment-Method-Main MainWidthSection">
      <h2>Consent</h2>
      <div class="col-md-12 Main-Bot-Sec paymenyt-info-check">
        <div class="form-check check-ext">
          <input
            type="checkbox"
            class="form-check-input"
            id="exampleCheck1"
            v-model="quote.consent1"
            @change="checkConsent1($event)"
          />
          <label
            class="form-check-label"
            for="exampleCheck1"
            style="padding: 0px"
          >
            I consent to use my personal information for the purposes of
            obtaining insurance and administering my policy.
            <a
              href="https://www.phidirect.com/privacy-policy"
              target="_blank"
              style="color: #6aa442; text-decoration: underline"
              >(Privacy policy)</a
            >*
          </label>
        </div>
      </div>
      <div class="col-md-12 Main-Bot-Sec paymenyt-info-check">
        <div class="form-check check-ext">
          <input
            type="checkbox"
            class="form-check-input"
            id="exampleCheck2"
            @change="checkConsent2($event)"
          />
          <label
            class="form-check-label"
            for="exampleCheck2"
            style="padding: 0px"
          >
            By checking the box on the enrollment path to accept paperless
            communications. I agree to the Consent to Use Electric Records, and
            agree to go paperless.*
          </label>
        </div>
      </div>
    </div>
    <br />
    <div class="container credit-card-info-section">
      <div v-if="showForm == 'Card'">
        <button
          class="Plan-ConBtn Color-Yeloch"
          @click.prevent="payWithCreditCard"
        >
          COMPLETE PURCHASE
        </button>
      </div>
      <div v-if="showForm == 'Paypal'">
        <button class="Plan-ConBtn Color-Yeloch" @click.prevent="payWithPayPal">
          COMPLETE PURCHASE
        </button>
      </div>
      <div v-if="showForm == 'Apple'">
        <button
          class="Plan-ConBtn Color-Yeloch btt"
          @click.prevent="payWithApplePay"
        >
          COMPLETE PURCHASE
        </button>
      </div>
      <div class="alert alert-success mx-auto mt-4" v-if="nonce">
        Thank you. One moment, please.
      </div>
      <div class="alert alert-danger mx-auto mt-4" v-if="error">
        {{ error }}
      </div>
    </div>
    <p
      class="Bot-Text-Terms pt-3"
      v-if="showForm == 'Card' || showForm == 'Paypal' || showForm == 'Apple'"
    >
      By clicking Complete Purchase you agree to our
      <br />
      <span>
        <a
          href="https://www.phidirect.com/terms-of-use-and-regulatory"
          target="_blank"
          >Terms and Conditions</a
        ></span
      >
    </p>
    <p class="back-quote">
      <a href="/info">&lt;&lt; Back to Info</a>
    </p>
  </div>
</template>
<script>
import Dropin from ".././components/dropIn.vue";
import ApplePay from ".././components/applePay.vue";
// import HostedFields from ".././components/hostedfields.vue";
import Header from "../components/header.vue";
import braintree from "braintree-web";
import axios from "axios";
import { client as config } from "../config";
// import braintree from 'braintree';

export default {
  name: "app",
  title: "Get PHI Direct – Payment and Completion",
  components: {
    Header,
    Dropin,
    ApplePay,
    // HostedFields,
  },
  data() {
    return {
      PetCount: 0,
      hostedFieldInstance: true,
      nonce: "",
      error: "",
      amount: 0,
      Amount: "",
      SavedquoteKey: "",
      infos: {},
      hostedFieldInstanceValue: "",

      authToken: "",
      //error: null,
      isQuote: false,
      isinfo: false,
      ispayment: true,
      showheadDiv: true,
      QuoteTotal: 0,
      feeTax: 0,
      feeName: 0,
      currentDay: "",
      txn: {
        options: {
          submitForSettlement: true,
        },
        payment: {
          amount: "",
          merchantAccountId: "whildcaught",
          paymentMethodNonce: "",
          paymentMethodToken: "",
          paypal: "",
        },
      },
      styling: {
        objectcolor: "#ff9633",
        animationcolor: "#ffffff",
        animationcolor2: "#17fd3d",
        objectbg: "#999793",
        opacity: 80,
        bgcolor: "#343a40",
        size: 5,
        speed: 2,
        disableScrolling: false,
      },
      customerinfo: {
        Address1: "",
        Address2: "",
        ContactMethodId: 60789,
        DateOfBirth: "",
        Email: "",
        FirstName: "",
        HomePhone: "",
        LastName: "",
        MobilePhone: "",
        Postcode: "",
        County: "",
        TownCity: "",
        TitleId: "",
        SecondaryEmail: "",
        SecondaryFirstName: "",
        SecondaryLastName: "",
        SecondaryHomePhone: "",
        SecondaryMobilePhone: "",
        SecondaryTitleId: "",
        DoNotEmail: true,
        DoNotPhone: true,
        DoNotPost: true,
        DoNotSms: true,
      },
      paymentInfo: {
        CreditCardToken: "",
        CreditCardType: "",
        CreditCardHolderName: "",
        PostalCode: "",
        CreditCardExpireDate: "",
        CreditCardNumberMasked: "",
        PaymentIntervalId: 4,
        PaymentMethodTypeId: 0,
        PaymentDay: 15,
        ReimbursementAccountNumber: "",
        ReimbursementBankAccountName: "",
        ReimbursementInstitutionNumber: "",
        ReimbursementSortCode: "",
        //TotalGrossPremium: "",
      },
      BirthDate: "",
      BreedId: 0,
      Gender: "",
      PetName: "",
      SpeciesId: "",
      vetId: 1026,
      vetName: "",
      petsInfos: [],
      underwritingAnswerItems: [],
      underwritingAnswerItemss: [],
      underQuestions: [],
      SessionId: 0,
      PaymentMethodId: 0,
      petsDetailsInfo: [],
      AnswerText: "",
      AnswerId: 0,
      QuestionId: 0,
      check2: "",
      PostalCodes: "",
      Emails: "",
      policyItem: [],
      policyDetailsItems: [],
      CoInsurance: 0,
      Excess: 0,
      OptionalCoverages: "",
      PolicyLimit: 0,
      ProductId: 0,
      pet1_id: "",
      pet1_name: "",
      pet1_species: "",
      pet2_id: "",
      pet2_name: "",
      pet2_species: "",
      pet3_id: "",
      pet3_name: "",
      pet3_species: "",
      pet4_id: "",
      pet4_name: "",
      pet4_species: "",
      pet5_id: "",
      pet5_name: "",
      pet5_species: "",
      objectId: 0,
      VoluntaryExcess: 0,
      quote: {
        postalCode: "",
        consent1: "",
        accountHolder: "",
        transitNumber: "",
        institutionNumber: "",
        accountNumber: "",
        authToken: "",
        checkInfo: "",
        Reumb: "",
      },
      petsInfo: [],
      adminFee: "",
      showForm: "",
      TotalMonthlyCost: 0,
      TotalMonthlyPremium: 0,
      TodayTotal: 0,
      policyTerm: [],
      selectPayment: "Card",
      nthDate: "",
    };
  },

  computed: {},

  async beforeMount() {
    this.scrollToTop();

    let paymentMethods = localStorage.getItem("PaymentMethod");
    let paymentMethod = JSON.parse(paymentMethods);
    this.showForm = paymentMethod ? paymentMethod : "Card";
    this.selectPayment = paymentMethod ? paymentMethod : "Card";
    paymentMethod = this.selectPayment;
    if (this.selectPayment == "Card") {
      this.CreditcardgenToken();
    }

    if (
      !localStorage.getItem("PetPolicyList") ||
      !localStorage.getItem("InfoDetails")
    ) {
      this.$router.push({ name: "Home" });
    }

    let getPolicyValue = localStorage.getItem("PetPolicyList");
    let quoteInfo = JSON.parse(getPolicyValue);

    this.PetCount = quoteInfo.petInfo.petPolicyList.length;
    quoteInfo.petInfo.petPolicyList.forEach((quotes) => {
      if (quotes.petInfo && quotes.petInfo.petName) {
        this.petsInfo.push(quotes);
      }
      quotes.policyList.forEach((policyes) => {
        if (policyes.selected === true) {
         // policyes.tax.forEach((taxes) => {
         //   this.TotalMonthlyCost = policyes.firstMonthly + taxes.firstMonthlyTaxLevel2;
         //   this.TotalMonthlyPremium += this.TotalMonthlyCost;
         // });
          debugger;
          policyes.enrollmentFees.forEach((enFee) => {
            debugger;
             this.TotalMonthlyCost = policyes.firstMonthly + (enFee.fee * enFee.feeTax/100);
            this.TotalMonthlyPremium += this.TotalMonthlyCost;

            if (enFee.fee === 0 && enFee.feeTax === 0) {
              // this.feeName += enFee.fee * this.petsInfo.length;
              this.feeName += enFee.fee;
            this.feeTax += enFee.feeTax;
              //this.feeTax += enFee.feeTax * this.petsInfo.length;
             
            } else {
              this.feeName += enFee.fee;
              this.feeTax += enFee.feeTax;
              
            }
          });
          this.policyTerm.push(policyes);
        }
      });
    });
    this.adminFee = quoteInfo.petInfo.adminFee.toFixed(2);
    this.TodayTotal = (
      this.TotalMonthlyPremium +
      this.feeName
    ).toFixed(2);
    localStorage.setItem("PaymentAmount", JSON.stringify(this.TodayTotal));
    this.getQuotetotal();
    var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    this.currentDay = `${currentDate.getDate()}`;
    this.nthDate = this.nth(this.currentDay);

    let quoteInfos = JSON.parse(getPolicyValue);
    this.SessionId = quoteInfos.petInfo.sessionId;
    this.SavedquoteKey = quoteInfos.petInfo.savedQuoteKey;
    quoteInfos.petInfo.petPolicyList.forEach((quotes) => {
      quotes.policyList.forEach((policyDetails) => {
        if (policyDetails.selected === true) {
          this.policyItem.push(policyDetails);
        }
      });
    });
    let emails = localStorage.getItem("Email");
    let email = JSON.parse(emails);
    this.Emails = email;
    let postCode = localStorage.getItem("PostalCode");
    this.PostalCodes = JSON.parse(postCode);
    this.quote.postalCode = this.PostalCodes;
    this.quote.consent1 = "";
    this.check2 = "";
    if (localStorage.getItem("ObjectId") != null) {
      let objectIds = localStorage.getItem("ObjectId");
      let objectidss = JSON.parse(objectIds);
      this.objectsIds = objectidss;
    }
    if (paymentMethod === "Card") {
      this.PaymentMethodId = 193355;
    }
    if (paymentMethod === "Paypal") {
      this.PaymentMethodId = 193366;
    }
    if (paymentMethod === "Apple") {
      this.PaymentMethodId = 193365;
    }
    let quoteInfor = JSON.parse(getPolicyValue);
    quoteInfor.petInfo.petPolicyList.forEach((quotes) => {
      this.petsDetailsInfo.push(quotes);
    });

    this.petsDetailsInfo.forEach((ques) => {
      let questions = {
        AnswerText: "",
        AnswerId: 0,
        QuestionId: 0,
      };
      this.AnswerText = ques.questions[0].messageIfYes;
      this.AnswerId = parseInt(ques.questions[0].selected);
      this.QuestionId = ques.questions[0].questionID;

      questions.AnswerText = this.AnswerText;
      questions.AnswerId = this.AnswerId;
      questions.QuestionId = this.QuestionId;
      this.underwritingAnswerItems.push(questions);
    });

    this.queValue = this.$cookies.get("hubspotutk");
  },

  methods: {
    nth(d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    },
    upper(e) {
      e.target.value = e.target.value.toUpperCase();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    checkConsent1(e) {
      if (e.target.checked) {
        this.quote.consent1 == "Consent1";
      } else {
        this.quote.consent1 = "";
      }
    },

    checkConsent2(ev) {
      if (ev.target.checked) {
        this.check2 = "Consent2";
      } else {
        this.check2 = "";
      }
    },
    onChange(event) {
      this.loader();
      this.showForm = event.target.value;
      localStorage.setItem("PaymentMethod", JSON.stringify(event.target.value));
      localStorage.removeItem("Paypalnonce");
      localStorage.removeItem("Applenonce");
      window.location.reload();
    },
    getQuotetotal() {
      let getPolicyValue = localStorage.getItem("PetPolicyList");
      let quoteInfo = JSON.parse(getPolicyValue);
      let policeslist = quoteInfo.petInfo.petPolicyList;
      //let QuoteTotal=0;
      policeslist.forEach((policy) => {
        policy.policyList.forEach((policyes) => {
          if (policyes.selected === true) {
            this.QuoteTotal += policyes.recurringMonthly;
          }
          // this.QuoteTotal += policy.policyList[0].recurringMonthly;
        });
      });
    },
    payWithCreditCard() {
      const checkBox1 = this.quote.consent1;
      const checkBox2 = this.check2;
      let amounts = localStorage.getItem("PaymentAmount");
      this.amount = JSON.parse(amounts);
      const additionalFields = {
        cardholderName: "",
        currency: "$",
        amount: "$" + this.amount,
        billingAddress: {
          postalCode: "",
        },
      };
      if (this.collectCardHolderName) {
        additionalFields.cardholderName = document.querySelector(
          "#cardholder"
        ).value;
      }

      try {
        if (this.hostedFieldInstance) {
          this.error = "";
          this.nonce = "";
          this.hostedFieldInstance
            .tokenize()
            .then((payload) => {
              if (
                this.quote.postalCode === "" ||
                this.quote.postalCode === null
              ) {
                this.isLogged = false;
                this.error = "Postal code required";
                return;
              }
              if (this.isPostalCodeValid(this.quote.postalCode) === false) {
                return;
              }
              // let nReumb = this.quote.Reumb;
              // if (nReumb != "") {
              //   if (nReumb == "yes") {
              //     if (
              //       this.quote.accountHolder === "" ||
              //       this.quote.accountHolder === null
              //     ) {
              //       this.isLogged = false;
              //       this.error = "Account holder name required.";
              //       return;
              //     }
              //     if (
              //       this.quote.transitNumber === "" ||
              //       this.quote.transitNumber === null
              //     ) {
              //       this.isLogged = false;
              //       this.error = "Transit number required.";
              //       return;
              //     }
              //     if (
              //       this.quote.institutionNumber === "" ||
              //       this.quote.institutionNumber === null
              //     ) {
              //       this.isLogged = false;
              //       this.error = "Institution number required.";
              //       return;
              //     }
              //     if (
              //       this.quote.accountNumber === "" ||
              //       this.quote.accountNumber === null
              //     ) {
              //       this.isLogged = false;
              //       this.error = "Account number required.";
              //       return;
              //     }
              //   }
              // } else {
              //   this.isLogged = false;
              //   this.error = "Please select Direct Deposit.";
              //   return;
              // }

              if (checkBox1 === "" || checkBox2 === "") {
                this.isLogged = false;
                this.error =
                  "You must agree to the consent checkboxes in order to complete your purchase";
                return;
              }
              if (this.quote.postalCode.split("")[0] === "G") {
                this.isLogged = false;
                this.error =
                  "We're sorry, but PHI Direct is currently not available in the province of Quebec. Please verify postal code";
                return;
              }
              if (this.quote.postalCode.split("")[0] === "H") {
                this.isLogged = false;
                this.error =
                  "We're sorry, but PHI Direct is currently not available in the province of Quebec. Please verify postal code";
                return;
              }
              if (this.quote.postalCode.split("")[0] === "J") {
                this.isLogged = false;
                this.error =
                  "We're sorry, but PHI Direct is currently not available in the province of Quebec. Please verify postal code";
                return;
              } else {
                this.nonce = payload;
                this.loader();
                this.nonce.details.cardholderName =
                  additionalFields.cardholderName;
                if (
                  this.quote.postalCode != null &&
                  this.quote.postalCode != undefined
                ) {
                  this.quote.postalCode = this.quote.postalCode.replace(
                    /\s/g,
                    ""
                  );
                  var x = this.quote.postalCode.slice(0, 3);
                  var xy = this.quote.postalCode.slice(3); //quote.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/);
                  this.quote.postalCode = x + " " + xy;
                } else {
                  this.isLogged = false;
                  this.error = "Postal code required";
                  return;
                }

                this.BuyPolicy(this.nonce, this.quote.postalCode);
                this.hostedFieldInstance.clear("number");
                this.hostedFieldInstance.clear("cvv");
                this.hostedFieldInstance.clear("expirationDate");
                document.querySelector("#postal").value = "";
                document.querySelector("#cardholder").value = "";
              }
            })
            .catch((err) => {
              console.error(err);
              this.error = "Please verify your credit card details";
            });
        }
      } catch (err) {
        console.error(err);
        this.error = err.message;
      }
    },
    isPostalCodeValid(postalcode) {
      const postalRe = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/;
      if (postalRe.test(postalcode)) {
        return true;
      } else {
        this.error = "The postal code is not valid";
        return false;
      }
    },
    async BuyPolicy(tokenizeDetail, postalCode) {
      console.log(this.PaymentMethodId);

      try {
        this.loader();
        //Customer Info
        var month = tokenizeDetail.details.expirationMonth;
        var year = tokenizeDetail.details.expirationYear;
        var last2digitYear = year.slice(-2);
        let expiryData = month + last2digitYear;
        let infoDetails = localStorage.getItem("InfoDetails");
        this.infos = JSON.parse(infoDetails);
        this.customerinfo.FirstName = this.infos.customerInfo.FirstName;
        this.customerinfo.LastName = this.infos.customerInfo.LastName;
        this.customerinfo.DateOfBirth = "1976-11-10T00:00:00";
        this.customerinfo.Email = this.Emails;
        this.customerinfo.Address1 = this.infos.customerInfo.Address1;
        this.customerinfo.Address2 = this.infos.customerInfo.Address2;
        this.customerinfo.ContactMethodId = 60789;
        this.customerinfo.MobilePhone = this.infos.customerInfo.MobilePhone;
        this.customerinfo.HomePhone = this.infos.customerInfo.HomePhone;
        this.customerinfo.County = this.infos.province;
        this.customerinfo.TownCity = this.infos.customerInfo.TownCity;
        if (
          this.infos.customerInfo.Postcode != null &&
          this.infos.customerInfo.Postcode != undefined &&
          this.infos.customerInfo.Postcode != ""
        ) {
          this.infos.customerInfo.Postcode = this.infos.customerInfo.Postcode.replace(
            /\s/g,
            ""
          );
          var x = this.infos.customerInfo.Postcode.slice(0, 3);
          var xy = this.infos.customerInfo.Postcode.slice(3); //quote.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/);
          this.infos.customerInfo.Postcode = x + " " + xy;
        }
        this.customerinfo.Postcode = this.infos.customerInfo.Postcode;
        this.customerinfo.TitleId = 0;
        this.customerinfo.SecondaryEmail = this.infos.customerInfo.SecondaryEmail;
        this.customerinfo.SecondaryFirstName = this.infos.customerInfo.SecondaryFirstName;
        this.customerinfo.SecondaryLastName = this.infos.customerInfo.SecondaryLastName;
        this.customerinfo.SecondaryHomePhone = this.infos.customerInfo.SecondaryHomePhone;
        this.customerinfo.SecondaryMobilePhone = this.infos.customerInfo.SecondaryMobilePhone;
        this.customerinfo.SecondaryTitleId = 0;
        this.customerinfo.DoNotEmail = true;
        this.customerinfo.DoNotPhone = true;
        this.customerinfo.DoNotPost = true;
        this.customerinfo.DoNotSms = true;
        //Payment Info
        this.paymentInfo.CreditCardHolderName =
          tokenizeDetail.details.cardholderName;
        this.paymentInfo.PostalCode = postalCode;
        this.paymentInfo.CreditCardExpireDate = expiryData;
        this.paymentInfo.CreditCardNumberMasked =
          tokenizeDetail.details.lastFour;
        this.paymentInfo.CreditCardType = tokenizeDetail.type;
        this.paymentInfo.CreditCardToken = tokenizeDetail.nonce;
        this.paymentInfo.PaymentIntervalId = 4;
        this.paymentInfo.PaymentMethodTypeId = this.PaymentMethodId;
        this.paymentInfo.PaymentDay = parseInt(this.currentDay);
        this.paymentInfo.ReimbursementAccountNumber = this.quote.accountNumber;
        this.paymentInfo.ReimbursementBankAccountName = this.quote.accountHolder;
        this.paymentInfo.ReimbursementInstitutionNumber = this.quote.institutionNumber;
        this.paymentInfo.ReimbursementSortCode = this.quote.transitNumber;

        //Pets Info
        let getPolicyValue = localStorage.getItem("PetPolicyList");
        let quoteInfo = JSON.parse(getPolicyValue);
        quoteInfo.petInfo.petPolicyList.forEach((quotes) => {
          if (quotes.petInfo && quotes.petInfo.petName) {
            this.petsInfo.push(quotes.petInfo);
          }
        });

        this.policyItem.forEach((policys) => {
          let policy = {
            CoInsurance: 0,
            Excess: 0,
            OptionalCoverages: "",
            PolicyLimit: 0,
            ProductId: 0,
            VoluntaryExcess: 0,
          };
          this.CoInsurance = policys.coInsurance.selected;
          this.Excess = policys.excess.selected;
          this.OptionalCoverages = policys.optionalCoverages;
          this.PolicyLimit = policys.policyLimit.selected;
          this.ProductId = policys.productId;
          this.VoluntaryExcess = policys.voluntaryExcess.selected;

          policy.CoInsurance = this.CoInsurance;
          policy.Excess = this.Excess;
          policy.OptionalCoverages = this.OptionalCoverages;
          policy.PolicyLimit = this.PolicyLimit;
          policy.ProductId = this.ProductId;
          policy.VoluntaryExcess = this.VoluntaryExcess;
          this.policyDetailsItems.push(policy);
        });
        let vvpetsDetails = this.petsDetailsInfo;

        this.petsInfo.forEach((pets, index) => {
          if (pets.petInfo && pets.petInfo.petName != undefined) {
            let petinfo = {
              BirthDate: "",
              BreedId: "",
              Gender: "",
              PetName: "",
              SpeciesId: "",
              VetId: parseInt(vvpetsDetails[index].questions[2].Id),
              VetName: this.petsDetailsInfo[index].questions[2].VetName,
              UnderwritingAnswerItemTypes: [
                this.underwritingAnswerItems[index],
                this.underwritingAnswerItemss[index],
              ],
              PolicyValues: [this.policyDetailsItems[index]],
            };
            let _petInfo = pets.petInfo;
            this.BirthDate = _petInfo.birthDate;
            this.BreedId = _petInfo.breedId;
            this.Gender = _petInfo.gender;
            this.PetName = _petInfo.petName;
            this.SpeciesId = _petInfo.speciesId;

            petinfo.BirthDate = this.BirthDate;
            petinfo.BreedId = this.BreedId;
            petinfo.Gender = this.Gender;
            petinfo.PetName = this.PetName;
            petinfo.SpeciesId = this.SpeciesId;
            this.petsInfos.push(petinfo);
          }
        });

        let petdetails = JSON.stringify(this.petsInfos);
        localStorage.setItem("PetDetails", petdetails);

        getPolicyValue = localStorage.getItem("PetPolicyList");
        let pet = JSON.parse(getPolicyValue);

        if (pet.petInfo.petPolicyList.length <= 5) {
          if (pet.petInfo.petPolicyList.length >= 1) {
            let petRefs1 = pet.petInfo.petPolicyList[0].petInfo.petRef
              ? pet.petInfo.petPolicyList[0].petInfo.petRef
              : "";
            this.pet1_id = petRefs1;
            let petName1 = pet.petInfo.petPolicyList[0].petInfo.petName
              ? pet.petInfo.petPolicyList[0].petInfo.petName
              : "";
            this.pet1_name = petName1;
            let speciesId1 = pet.petInfo.petPolicyList[0].petInfo.speciesId
              ? pet.petInfo.petPolicyList[0].petInfo.speciesId
              : "";
            this.pet1_species = speciesId1;
          }

          if (pet.petInfo.petPolicyList.length >= 2) {
            let petRefs2 = pet.petInfo.petPolicyList[1].petInfo.petRef
              ? pet.petInfo.petPolicyList[1].petInfo.petRef
              : "";
            this.pet2_id = petRefs2;
            let petName2 = pet.petInfo.petPolicyList[1].petInfo.petName
              ? pet.petInfo.petPolicyList[1].petInfo.petName
              : "";
            this.pet2_name = petName2;
            let speciesId2 = pet.petInfo.petPolicyList[1].petInfo.speciesId
              ? pet.petInfo.petPolicyList[1].petInfo.speciesId
              : "";
            this.pet2_species = speciesId2;
          }

          if (pet.petInfo.petPolicyList.length >= 3) {
            let petRefs3 = pet.petInfo.petPolicyList[2].petInfo.petRef
              ? pet.petInfo.petPolicyList[2].petInfo.petRef
              : "";
            this.pet3_id = petRefs3;
            let petName3 = pet.petInfo.petPolicyList[2].petInfo.petName
              ? pet.petInfo.petPolicyList[2].petInfo.petName
              : "";
            this.pet3_name = petName3;
            let speciesId3 = pet.petInfo.petPolicyList[2].petInfo.speciesId
              ? pet.petInfo.petPolicyList[2].petInfo.speciesId
              : "";
            this.pet3_species = speciesId3;
          }

          if (pet.petInfo.petPolicyList.length >= 4) {
            let petRefs4 = pet.petInfo.petPolicyList[3].petInfo.petRef
              ? pet.petInfo.petPolicyList[3].petInfo.petRef
              : "";
            this.pet4_id = petRefs4;
            let petName4 = pet.petInfo.petPolicyList[3].petInfo.petName
              ? pet.petInfo.petPolicyList[3].petInfo.petName
              : "";
            this.pet4_name = petName4;
            let speciesId4 = pet.petInfo.petPolicyList[3].petInfo.speciesId
              ? pet.petInfo.petPolicyList[3].petInfo.speciesId
              : "";
            this.pet4_species = speciesId4;
          }

          if (pet.petInfo.petPolicyList.length >= 5) {
            let petRefs5 = pet.petInfo.petPolicyList[4].petInfo.petRef
              ? pet.petInfo.petPolicyList[4].petInfo.petRef
              : "";
            this.pet5_id = petRefs5;
            let petName5 = pet.petInfo.petPolicyList[4].petInfo.petName
              ? pet.petInfo.petPolicyList[4].petInfo.petName
              : "";
            this.pet5_name = petName5;
            let speciesId5 = pet.petInfo.petPolicyList[4].petInfo.speciesId
              ? pet.petInfo.petPolicyList[4].petInfo.speciesId
              : "";
            this.pet5_species = speciesId5;
          }
          // if (this.objectsIds === null) {
          //   this.objectsIds = pet.petInfo.responseId;
          // }

          if (localStorage.getItem("ObjectId") != null) {
            let objectIds = localStorage.getItem("ObjectId");
            let objectidss = JSON.parse(objectIds);
            this.objectsIds = objectidss;
          } else {
            this.objectsIds = 0;
          }

          //Activity
          let Postmodel = {
            CustomerInfo: this.customerinfo,
            PaymentInfo: this.paymentInfo,
            PetsInfo: this.petsInfos,
            SessionId: this.SessionId,
            SavedQuoteKey: this.SavedquoteKey,
            pet1_id: this.pet1_id,
            pet1_name: this.pet1_name,
            pet1_species: this.pet1_species.toString(),
            pet2_id: this.pet2_id,
            pet2_name: this.pet2_name,
            pet2_species: this.pet2_species.toString(),
            pet3_id: this.pet3_id,
            pet3_name: this.pet3_name,
            pet3_species: this.pet3_species.toString(),
            pet4_id: this.pet4_id,
            pet4_name: this.pet4_name,
            pet4_species: this.pet4_species.toString(),
            pet5_id: this.pet5_id,
            pet5_name: this.pet5_name,
            pet5_species: this.pet5_species.toString(),
            objectId: parseInt(this.objectsIds),
            cookieValue: this.queValue,
          };

          let url = config.api.policyUrl + "BuyPolicy";
          let response = await axios.post(url, Postmodel);
          debugger;
          if (response.data.responseId === null) {
            if (response.data.result === true) {
              let policyNumber = JSON.stringify(response.data);
              localStorage.setItem("BuyPolicyData", policyNumber);
              localStorage.removeItem("InfoDetails");
              localStorage.removeItem("PetPolicyList");
              localStorage.removeItem("PostalCode");
              localStorage.removeItem("ObjectId");
              this.$router.push({ name: "welcome" });
            }
          } else if (
            response.data.responseId ==
            "Email address required. Please enter your email address here to continue to complete your purchase"
          ) {
            response.data.responseId =
              "Email address required. Please enter your email address here to continue to complete your purchase";

            this.$toast(response.data.responseId, {
              styles: {
                background: "Coral",
              },
              type: "error",
              positionY: "top",
            });
            setTimeout(() => {
              window.location.href = "/";
            }, 4000);
          } else {
            this.$toast(response.data.responseId, {
              styles: {
                background: "Coral",
              },
              type: "error",
              positionY: "top",
            });
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    payWithPayPal() {
      let _Paypalnonce = localStorage.getItem("Paypalnonce");
      const checkBox1 = this.quote.consent1;
      const checkBox2 = this.check2;
      this.error = "";
      this.success = false;
      if (_Paypalnonce === "" || _Paypalnonce === null) {
        this.error = "Please complete your payment details.";
        return;
      }

      // let nReumb = this.quote.Reumb;
      // if (nReumb != "") {
      //   if (nReumb == "yes") {
      //     if (
      //       this.quote.accountHolder === "" ||
      //       this.quote.accountHolder === null
      //     ) {
      //       this.isLogged = false;
      //       this.error = "Account holder name required.";
      //       return;
      //     }
      //     if (
      //       this.quote.transitNumber === "" ||
      //       this.quote.transitNumber === null
      //     ) {
      //       this.isLogged = false;
      //       this.error = "Transit number required.";
      //       return;
      //     }
      //     if (
      //       this.quote.institutionNumber === "" ||
      //       this.quote.institutionNumber === null
      //     ) {
      //       this.isLogged = false;
      //       this.error = "Institution number required.";
      //       return;
      //     }
      //     if (
      //       this.quote.accountNumber === "" ||
      //       this.quote.accountNumber === null
      //     ) {
      //       this.isLogged = false;
      //       this.error = "Account number required.";
      //       return;
      //     }
      //   }
      // } else {
      //   this.isLogged = false;
      //   this.error = "Please select Direct Deposit.";
      //   return;
      // }

      if (checkBox1 === "" || checkBox2 === "") {
        this.isLogged = false;
        this.error =
          "You must agree to the consent checkboxes in order to complete your purchase";
        return;
      } else {
        if (this.error === "" && _Paypalnonce != "") {
          this.success = true;
          this.loader();
          this.PaywithPayPalBuyPolicy(_Paypalnonce);
        }
      }
    },

    async PaywithPayPalBuyPolicy(token) {
      try {
        //Customer Info
        let infoDetails = localStorage.getItem("InfoDetails");
        this.infos = JSON.parse(infoDetails);
        this.customerinfo.FirstName = this.infos.customerInfo.FirstName;
        this.customerinfo.LastName = this.infos.customerInfo.LastName;
        this.customerinfo.DateOfBirth = "1976-11-10T00:00:00";
        this.customerinfo.Email = this.Emails;
        this.customerinfo.Address1 = this.infos.customerInfo.Address1;
        this.customerinfo.Address2 = this.infos.customerInfo.Address2;
        this.customerinfo.ContactMethodId = 60789;
        this.customerinfo.MobilePhone = this.infos.customerInfo.MobilePhone;
        this.customerinfo.HomePhone = this.infos.customerInfo.HomePhone;
        this.customerinfo.County = this.infos.province;
        this.customerinfo.TownCity = this.infos.customerInfo.TownCity;
        if (
          this.infos.customerInfo.Postcode != null &&
          this.infos.customerInfo.Postcode != undefined &&
          this.infos.customerInfo.Postcode != ""
        ) {
          this.infos.customerInfo.Postcode = this.infos.customerInfo.Postcode.replace(
            /\s/g,
            ""
          );
          var x = this.infos.customerInfo.Postcode.slice(0, 3);
          var xy = this.infos.customerInfo.Postcode.slice(3); //quote.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/);
          this.infos.customerInfo.Postcode = x + " " + xy;
        }
        this.customerinfo.Postcode = this.infos.customerInfo.Postcode;
        this.customerinfo.TitleId = 0;
        this.customerinfo.SecondaryEmail = this.infos.customerInfo.SecondaryEmail;
        this.customerinfo.SecondaryFirstName = this.infos.customerInfo.SecondaryFirstName;
        this.customerinfo.SecondaryLastName = this.infos.customerInfo.SecondaryLastName;
        this.customerinfo.SecondaryHomePhone = this.infos.customerInfo.SecondaryHomePhone;
        this.customerinfo.SecondaryMobilePhone = this.infos.customerInfo.SecondaryMobilePhone;
        this.customerinfo.SecondaryTitleId = 0;
        this.customerinfo.DoNotEmail = true;
        this.customerinfo.DoNotPhone = true;
        this.customerinfo.DoNotPost = true;
        this.customerinfo.DoNotSms = true;

        //Payment Info
        this.paymentInfo.CreditCardToken = token;
        this.paymentInfo.PaymentIntervalId = 4;
        this.paymentInfo.PaymentMethodTypeId = this.PaymentMethodId;
        this.paymentInfo.PaymentDay = parseInt(this.currentDay);
        this.paymentInfo.ReimbursementAccountNumber = this.quote.accountNumber;
        this.paymentInfo.ReimbursementBankAccountName = this.quote.accountHolder;
        this.paymentInfo.ReimbursementInstitutionNumber = this.quote.institutionNumber;
        this.paymentInfo.ReimbursementSortCode = this.quote.transitNumber;

        //Pets Info
        let getPolicyValue = localStorage.getItem("PetPolicyList");
        let quoteInfo = JSON.parse(getPolicyValue);
        quoteInfo.petInfo.petPolicyList.forEach((quotes) => {
          this.petsInfo.push(quotes.petInfo);
        });

        this.policyItem.forEach((policys) => {
          let policy = {
            CoInsurance: 0,
            Excess: 0,
            OptionalCoverages: "",
            PolicyLimit: 0,
            ProductId: 0,
            VoluntaryExcess: 0,
          };
          this.CoInsurance = policys.coInsurance.selected;
          this.Excess = policys.excess.selected;
          this.OptionalCoverages = policys.optionalCoverages;
          this.PolicyLimit = policys.policyLimit.selected;
          this.ProductId = policys.productId;
          this.VoluntaryExcess = policys.voluntaryExcess.selected;

          policy.CoInsurance = this.CoInsurance;
          policy.Excess = this.Excess;
          policy.OptionalCoverages = this.OptionalCoverages;
          policy.PolicyLimit = this.PolicyLimit;
          policy.ProductId = this.ProductId;
          policy.VoluntaryExcess = this.VoluntaryExcess;
          this.policyDetailsItems.push(policy);
        });

        this.petsInfo.forEach((pets, index) => {
          if (pets.petInfo && pets.petInfo.petName != undefined) {
            let petinfo = {
              BirthDate: "",
              BreedId: "",
              Gender: "",
              PetName: "",
              SpeciesId: "",
              VetId: parseInt(this.petsDetailsInfo[index].questions[2].Id),
              VetName: this.petsDetailsInfo[index].questions[2].VetName,
              UnderwritingAnswerItemTypes: [
                this.underwritingAnswerItems[index],
                this.underwritingAnswerItemss[index],
              ],
              PolicyValues: [this.policyDetailsItems[index]],
            };

            let _petInfo = pets.petInfo;
            this.BirthDate = _petInfo.birthDate;
            this.BreedId = _petInfo.breedId;
            this.Gender = _petInfo.gender;
            this.PetName = _petInfo.petName;
            this.SpeciesId = _petInfo.speciesId;

            petinfo.BirthDate = this.BirthDate;
            petinfo.BreedId = this.BreedId;
            petinfo.Gender = this.Gender;
            petinfo.PetName = this.PetName;
            petinfo.SpeciesId = this.SpeciesId;
            this.petsInfos.push(petinfo);
          }
        });

        let petdetails = JSON.stringify(this.petsInfos);
        localStorage.setItem("PetDetails", petdetails);

        getPolicyValue = localStorage.getItem("PetPolicyList");
        let pet = JSON.parse(getPolicyValue);

        if (pet.petInfo.petPolicyList.length <= 5) {
          if (pet.petInfo.petPolicyList.length >= 1) {
            let petRefs1 = pet.petInfo.petPolicyList[0].petInfo.petRef
              ? pet.petInfo.petPolicyList[0].petInfo.petRef
              : "";
            this.pet1_id = petRefs1;
            let petName1 = pet.petInfo.petPolicyList[0].petInfo.petName
              ? pet.petInfo.petPolicyList[0].petInfo.petName
              : "";
            this.pet1_name = petName1;
            let speciesId1 = pet.petInfo.petPolicyList[0].petInfo.speciesId
              ? pet.petInfo.petPolicyList[0].petInfo.speciesId
              : "";
            this.pet1_species = speciesId1;
          }

          if (pet.petInfo.petPolicyList.length >= 2) {
            let petRefs2 = pet.petInfo.petPolicyList[1].petInfo.petRef
              ? pet.petInfo.petPolicyList[1].petInfo.petRef
              : "";
            this.pet2_id = petRefs2;
            let petName2 = pet.petInfo.petPolicyList[1].petInfo.petName
              ? pet.petInfo.petPolicyList[1].petInfo.petName
              : "";
            this.pet2_name = petName2;
            let speciesId2 = pet.petInfo.petPolicyList[1].petInfo.speciesId
              ? pet.petInfo.petPolicyList[1].petInfo.speciesId
              : "";
            this.pet2_species = speciesId2;
          }

          if (pet.petInfo.petPolicyList.length >= 3) {
            let petRefs3 = pet.petInfo.petPolicyList[2].petInfo.petRef
              ? pet.petInfo.petPolicyList[2].petInfo.petRef
              : "";
            this.pet3_id = petRefs3;
            let petName3 = pet.petInfo.petPolicyList[2].petInfo.petName
              ? pet.petInfo.petPolicyList[2].petInfo.petName
              : "";
            this.pet3_name = petName3;
            let speciesId3 = pet.petInfo.petPolicyList[2].petInfo.speciesId
              ? pet.petInfo.petPolicyList[2].petInfo.speciesId
              : "";
            this.pet3_species = speciesId3;
          }

          if (pet.petInfo.petPolicyList.length >= 4) {
            let petRefs4 = pet.petInfo.petPolicyList[3].petInfo.petRef
              ? pet.petInfo.petPolicyList[3].petInfo.petRef
              : "";
            this.pet4_id = petRefs4;
            let petName4 = pet.petInfo.petPolicyList[3].petInfo.petName
              ? pet.petInfo.petPolicyList[3].petInfo.petName
              : "";
            this.pet4_name = petName4;
            let speciesId4 = pet.petInfo.petPolicyList[3].petInfo.speciesId
              ? pet.petInfo.petPolicyList[3].petInfo.speciesId
              : "";
            this.pet4_species = speciesId4;
          }

          if (pet.petInfo.petPolicyList.length >= 5) {
            let petRefs5 = pet.petInfo.petPolicyList[4].petInfo.petRef
              ? pet.petInfo.petPolicyList[4].petInfo.petRef
              : "";
            this.pet5_id = petRefs5;
            let petName5 = pet.petInfo.petPolicyList[4].petInfo.petName
              ? pet.petInfo.petPolicyList[4].petInfo.petName
              : "";
            this.pet5_name = petName5;
            let speciesId5 = pet.petInfo.petPolicyList[4].petInfo.speciesId
              ? pet.petInfo.petPolicyList[4].petInfo.speciesId
              : "";
            this.pet5_species = speciesId5;
          }

          if (localStorage.getItem("ObjectId") != null) {
            let objectIds = localStorage.getItem("ObjectId");
            let objectidss = JSON.parse(objectIds);
            this.objectsIds = objectidss;
          } else {
            this.objectsIds = 0;
          }
          //Activity
          let Postmodel = {
            CustomerInfo: this.customerinfo,
            PaymentInfo: this.paymentInfo,
            PetsInfo: this.petsInfos,
            SessionId: this.SessionId,
            SavedQuoteKey: this.SavedquoteKey,
            pet1_id: this.pet1_id,
            pet1_name: this.pet1_name,
            pet1_species: this.pet1_species.toString(),
            pet2_id: this.pet2_id,
            pet2_name: this.pet2_name,
            pet2_species: this.pet2_species.toString(),
            pet3_id: this.pet3_id,
            pet3_name: this.pet3_name,
            pet3_species: this.pet3_species.toString(),
            pet4_id: this.pet4_id,
            pet4_name: this.pet4_name,
            pet4_species: this.pet4_species.toString(),
            pet5_id: this.pet5_id,
            pet5_name: this.pet5_name,
            pet5_species: this.pet5_species.toString(),
            objectId: parseInt(this.objectsIds),
            cookieValue: this.queValue,
          };

          let url = config.api.policyUrl + "BuyPolicy";
          let response = await axios.post(url, Postmodel);
          if (response.data.responseId === null) {
            if (response.data.result === true) {
              let policyNumber = JSON.stringify(response.data);
              localStorage.setItem("BuyPolicyData", policyNumber);
              localStorage.removeItem("InfoDetails");
              localStorage.removeItem("PetPolicyList");
              localStorage.removeItem("PostalCode");
              localStorage.removeItem("ObjectId");
              localStorage.removeItem("Paypalnonce");
              this.$router.push({ name: "welcome" });
            }
          } else if (
            response.data.responseId ==
            "Email address required. Please enter your email address here to continue to complete your purchase"
          ) {
            response.data.responseId =
              "Email address required. Please enter your email address here to continue to complete your purchase";

            this.$toast(response.data.responseId, {
              styles: {
                background: "Coral",
              },
              type: "error",
              positionY: "top",
            });
            setTimeout(() => {
              window.location.href = "/";
            }, 4000);
          } else {
            this.$toast(response.data.responseId, {
              styles: {
                background: "Coral",
              },
              type: "error",
              positionY: "top",
            });
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    payWithApplePay() {
      const checkBox1 = this.quote.consent1;
      const checkBox2 = this.check2;
      this.error = "";
      let _Applenonce = localStorage.getItem("Applenonce");
      this.success = false;
      try {
        if (_Applenonce === "" || _Applenonce === null) {
          this.error = "Please process your payment";
          return;
        }
        // let nReumb = this.quote.Reumb;
        // if (nReumb != "") {
        //   if (nReumb == "yes") {
        //     if (
        //       this.quote.accountHolder === "" ||
        //       this.quote.accountHolder === null
        //     ) {
        //       this.error = "Account holder name required.";
        //       return;
        //     }
        //     if (
        //       this.quote.transitNumber === "" ||
        //       this.quote.transitNumber === null
        //     ) {
        //       this.error = "Transit number required.";
        //       return;
        //     }
        //     if (
        //       this.quote.institutionNumber === "" ||
        //       this.quote.institutionNumber === null
        //     ) {
        //       this.error = "Institution number required.";
        //       return;
        //     }
        //     if (
        //       this.quote.accountNumber === "" ||
        //       this.quote.accountNumber === null
        //     ) {
        //       this.error = "Account number required.";
        //       return;
        //     }
        //   }
        // } else {
        //   this.isLogged = false;
        //   this.error = "Please select Direct Deposit.";
        //   return;
        // }

        if (checkBox1 === "" || checkBox2 === "") {
          this.error =
            "You must agree to the consent checkboxes in order to complete your purchase";
          return;
        } else {
          if (this.error === "" && _Applenonce != "") {
            this.success = true;
            this.loader();
            this.AppleBuyPolicy(_Applenonce);
          }
        }
      } catch (err) {
        console.error(err);
        this.error = err.message;
      }
    },

    async AppleBuyPolicy(token) {
      try {
        //Customer Info
        let infoDetails = localStorage.getItem("InfoDetails");
        this.infos = JSON.parse(infoDetails);
        this.customerinfo.FirstName = this.infos.customerInfo.FirstName;
        this.customerinfo.LastName = this.infos.customerInfo.LastName;
        this.customerinfo.DateOfBirth = "1976-11-10T00:00:00";
        this.customerinfo.Email = this.Emails;
        this.customerinfo.Address1 = this.infos.customerInfo.Address1;
        this.customerinfo.Address2 = this.infos.customerInfo.Address2;
        this.customerinfo.ContactMethodId = 60789;
        this.customerinfo.MobilePhone = this.infos.customerInfo.MobilePhone;
        this.customerinfo.HomePhone = this.infos.customerInfo.HomePhone;
        this.customerinfo.County = this.infos.province;
        this.customerinfo.TownCity = this.infos.customerInfo.TownCity;
        if (
          this.infos.customerInfo.Postcode != null &&
          this.infos.customerInfo.Postcode != undefined &&
          this.infos.customerInfo.Postcode != ""
        ) {
          this.infos.customerInfo.Postcode = this.infos.customerInfo.Postcode.replace(
            /\s/g,
            ""
          );
          var x = this.infos.customerInfo.Postcode.slice(0, 3);
          var xy = this.infos.customerInfo.Postcode.slice(3); //quote.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/);
          this.infos.customerInfo.Postcode = x + " " + xy;
        }
        this.customerinfo.Postcode = this.infos.customerInfo.Postcode;
        this.customerinfo.TitleId = 0;
        this.customerinfo.SecondaryEmail = this.infos.customerInfo.SecondaryEmail;
        this.customerinfo.SecondaryFirstName = this.infos.customerInfo.SecondaryFirstName;
        this.customerinfo.SecondaryLastName = this.infos.customerInfo.SecondaryLastName;
        this.customerinfo.SecondaryHomePhone = this.infos.customerInfo.SecondaryHomePhone;
        this.customerinfo.SecondaryMobilePhone = this.infos.customerInfo.SecondaryMobilePhone;
        this.customerinfo.SecondaryTitleId = 0;
        this.customerinfo.DoNotEmail = true;
        this.customerinfo.DoNotPhone = true;
        this.customerinfo.DoNotPost = true;
        this.customerinfo.DoNotSms = true;

        //Payment Info
        this.paymentInfo.CreditCardToken = token;
        this.paymentInfo.CreditCardHolderName = this.cardHolderName;
        this.paymentInfo.CreditCardType = this.cardType;
        this.paymentInfo.PaymentIntervalId = 4;
        this.paymentInfo.PostalCode = this.PostalCodes;
        this.paymentInfo.PaymentMethodTypeId = this.PaymentMethodId;
        this.paymentInfo.PaymentDay = parseInt(this.currentDay);
        this.paymentInfo.ReimbursementAccountNumber = this.quote.accountNumber;
        this.paymentInfo.ReimbursementBankAccountName = this.quote.accountHolder;
        this.paymentInfo.ReimbursementInstitutionNumber = this.quote.institutionNumber;
        this.paymentInfo.ReimbursementSortCode = this.quote.transitNumber;

        //Pets Info
        let getPolicyValue = localStorage.getItem("PetPolicyList");
        let quoteInfo = JSON.parse(getPolicyValue);
        quoteInfo.petInfo.petPolicyList.forEach((quotes) => {
          this.petsInfo.push(quotes.petInfo);
        });

        this.policyItem.forEach((policys) => {
          let policy = {
            CoInsurance: 0,
            Excess: 0,
            OptionalCoverages: "",
            PolicyLimit: 0,
            ProductId: 0,
            VoluntaryExcess: 0,
          };
          this.CoInsurance = policys.coInsurance.selected;
          this.Excess = policys.excess.selected;
          this.OptionalCoverages = policys.optionalCoverages;
          this.PolicyLimit = policys.policyLimit.selected;
          this.ProductId = policys.productId;
          this.VoluntaryExcess = policys.voluntaryExcess.selected;

          policy.CoInsurance = this.CoInsurance;
          policy.Excess = this.Excess;
          policy.OptionalCoverages = this.OptionalCoverages;
          policy.PolicyLimit = this.PolicyLimit;
          policy.ProductId = this.ProductId;
          policy.VoluntaryExcess = this.VoluntaryExcess;
          this.policyDetailsItems.push(policy);
        });

        this.petsInfo.forEach((pets, index) => {
          if (pets.petInfo && pets.petInfo.petName != undefined) {
            let petinfo = {
              BirthDate: "",
              BreedId: "",
              Gender: "",
              PetName: "",
              SpeciesId: "",
              VetId: parseInt(this.petsDetailsInfo[index].questions[2].Id),
              VetName: this.petsDetailsInfo[index].questions[2].VetName,
              UnderwritingAnswerItemTypes: [
                this.underwritingAnswerItems[index],
                this.underwritingAnswerItemss[index],
              ],
              PolicyValues: [this.policyDetailsItems[index]],
            };

            let _petInfo = pets.petInfo;
            this.BirthDate = _petInfo.birthDate;
            this.BreedId = _petInfo.breedId;
            this.Gender = _petInfo.gender;
            this.PetName = _petInfo.petName;
            this.SpeciesId = _petInfo.speciesId;
            petinfo.BirthDate = this.BirthDate;
            petinfo.BreedId = this.BreedId;
            petinfo.Gender = this.Gender;
            petinfo.PetName = this.PetName;
            petinfo.SpeciesId = this.SpeciesId;
            this.petsInfos.push(petinfo);
          }
        });

        let petdetails = JSON.stringify(this.petsInfos);
        localStorage.setItem("PetDetails", petdetails);

        getPolicyValue = localStorage.getItem("PetPolicyList");
        let pet = JSON.parse(getPolicyValue);

        if (pet.petInfo.petPolicyList.length <= 5) {
          if (pet.petInfo.petPolicyList.length >= 1) {
            let petRefs1 = pet.petInfo.petPolicyList[0].petInfo.petRef
              ? pet.petInfo.petPolicyList[0].petInfo.petRef
              : "";
            this.pet1_id = petRefs1;
            let petName1 = pet.petInfo.petPolicyList[0].petInfo.petName
              ? pet.petInfo.petPolicyList[0].petInfo.petName
              : "";
            this.pet1_name = petName1;
            let speciesId1 = pet.petInfo.petPolicyList[0].petInfo.speciesId
              ? pet.petInfo.petPolicyList[0].petInfo.speciesId
              : "";
            this.pet1_species = speciesId1;
          }

          if (pet.petInfo.petPolicyList.length >= 2) {
            let petRefs2 = pet.petInfo.petPolicyList[1].petInfo.petRef
              ? pet.petInfo.petPolicyList[1].petInfo.petRef
              : "";
            this.pet2_id = petRefs2;
            let petName2 = pet.petInfo.petPolicyList[1].petInfo.petName
              ? pet.petInfo.petPolicyList[1].petInfo.petName
              : "";
            this.pet2_name = petName2;
            let speciesId2 = pet.petInfo.petPolicyList[1].petInfo.speciesId
              ? pet.petInfo.petPolicyList[1].petInfo.speciesId
              : "";
            this.pet2_species = speciesId2;
          }

          if (pet.petInfo.petPolicyList.length >= 3) {
            let petRefs3 = pet.petInfo.petPolicyList[2].petInfo.petRef
              ? pet.petInfo.petPolicyList[2].petInfo.petRef
              : "";
            this.pet3_id = petRefs3;
            let petName3 = pet.petInfo.petPolicyList[2].petInfo.petName
              ? pet.petInfo.petPolicyList[2].petInfo.petName
              : "";
            this.pet3_name = petName3;
            let speciesId3 = pet.petInfo.petPolicyList[2].petInfo.speciesId
              ? pet.petInfo.petPolicyList[2].petInfo.speciesId
              : "";
            this.pet3_species = speciesId3;
          }

          if (pet.petInfo.petPolicyList.length >= 4) {
            let petRefs4 = pet.petInfo.petPolicyList[3].petInfo.petRef
              ? pet.petInfo.petPolicyList[3].petInfo.petRef
              : "";
            this.pet4_id = petRefs4;
            let petName4 = pet.petInfo.petPolicyList[3].petInfo.petName
              ? pet.petInfo.petPolicyList[3].petInfo.petName
              : "";
            this.pet4_name = petName4;
            let speciesId4 = pet.petInfo.petPolicyList[3].petInfo.speciesId
              ? pet.petInfo.petPolicyList[3].petInfo.speciesId
              : "";
            this.pet4_species = speciesId4;
          }

          if (pet.petInfo.petPolicyList.length >= 5) {
            let petRefs5 = pet.petInfo.petPolicyList[4].petInfo.petRef
              ? pet.petInfo.petPolicyList[4].petInfo.petRef
              : "";
            this.pet5_id = petRefs5;
            let petName5 = pet.petInfo.petPolicyList[4].petInfo.petName
              ? pet.petInfo.petPolicyList[4].petInfo.petName
              : "";
            this.pet5_name = petName5;
            let speciesId5 = pet.petInfo.petPolicyList[4].petInfo.speciesId
              ? pet.petInfo.petPolicyList[4].petInfo.speciesId
              : "";
            this.pet5_species = speciesId5;
          }

          if (localStorage.getItem("ObjectId") != null) {
            let objectIds = localStorage.getItem("ObjectId");
            let objectidss = JSON.parse(objectIds);
            this.objectsIds = objectidss;
          } else {
            this.objectsIds = 0;
          }
          //Activity
          let Postmodel = {
            CustomerInfo: this.customerinfo,
            PaymentInfo: this.paymentInfo,
            PetsInfo: this.petsInfos,
            SessionId: this.SessionId,
            SavedQuoteKey: this.SavedquoteKey,
            pet1_id: this.pet1_id,
            pet1_name: this.pet1_name,
            pet1_species: this.pet1_species.toString(),
            pet2_id: this.pet2_id,
            pet2_name: this.pet2_name,
            pet2_species: this.pet2_species.toString(),
            pet3_id: this.pet3_id,
            pet3_name: this.pet3_name,
            pet3_species: this.pet3_species.toString(),
            pet4_id: this.pet4_id,
            pet4_name: this.pet4_name,
            pet4_species: this.pet4_species.toString(),
            pet5_id: this.pet5_id,
            pet5_name: this.pet5_name,
            pet5_species: this.pet5_species.toString(),
            objectId: parseInt(this.objectsIds),
            cookieValue: this.queValue,
          };
          let url = config.api.policyUrl + "BuyPolicy";
          let response = await axios.post(url, Postmodel);

          if (response.data.responseId === null) {
            if (response.data.result === true) {
              let policyNumber = JSON.stringify(response.data);
              localStorage.setItem("BuyPolicyData", policyNumber);
              localStorage.removeItem("InfoDetails");
              localStorage.removeItem("PetPolicyList");
              localStorage.removeItem("PostalCode");
              localStorage.removeItem("ObjectId");
              localStorage.removeItem("Applenonce");
              this.$router.push({ name: "welcome" });
            }
          } else if (
            response.data.responseId ==
            "Email address required. Please enter your email address here to continue to complete your purchase"
          ) {
            response.data.responseId =
              "Email address required. Please enter your email address here to continue to complete your purchase";

            this.$toast(response.data.responseId, {
              styles: {
                background: "Coral",
              },
              type: "error",
              positionY: "top",
            });
            setTimeout(() => {
              window.location.href = "/";
            }, 4000);
          } else {
            this.$toast(response.data.responseId, {
              styles: {
                background: "Coral",
              },
              type: "error",
              positionY: "top",
            });
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async CreditcardgenToken() {
      let url = config.api.generateTokenUrl + "GenerateToken";
      const response = await axios.post(url);
      let token = response.data;
      this.authToken = token;
      braintree.client
        .create({
          authorization: this.authToken,
        })
        .then((clientInstance) => {
          let options = {
            client: clientInstance,
            styles: {
              input: {
                "font-size": ".875rem",
              },
              "input.invalid": {
                color: "red",
              },
              "input.valid": {
                color: "green",
              },
            },
            fields: {
              number: {
                selector: "#creditCardNumber",
                placeholder: "4111 1111 1111 1111",
              },
              cvv: {
                selector: "#cvv",
                placeholder: "123",
              },
              expirationDate: {
                selector: "#expireDate",
                placeholder: "10/25",
              },
            },
          };
          return Promise.all([braintree.hostedFields.create(options)]);
        })
        .then((instances) => {
          const hostedFieldInstance = instances[0];
          // Use hostedFieldInstance to send data to Braintree
          this.hostedFieldInstance = hostedFieldInstance;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ShowHideDiv() {
      var chkYes = document.getElementById("chkYes");
      var dvtext = document.getElementById("dvtext");
      dvtext.style.display = "none";
      chkYes.style.display = "block";
      this.quote.Reumb = "yes";
    },

    HideDiv() {
      var chkYes = document.getElementById("chkYes");
      var dvtext = document.getElementById("dvtext");
      dvtext.style.display = "block";
      chkYes.style.display = "none";
      this.quote.Reumb = "no";
    },
    loader() {
      this.selected = "circular";
      this.code = `<loader object="${this.styling.objectcolor}" color1="${this.styling.animationcolor}" color2="${this.styling.animationcolor2}" size="${this.styling.size}" speed="${this.styling.speed}" bg="${this.styling.bgcolor}" objectbg="${this.styling.objectbg}" opacity="${this.styling.opacity}" disableScrolling="${this.styling.disableScrolling}" name="${this.selected}"></loader>`;
      setTimeout(() => {
        this.selected = "";
      }, this.value * 3000);
    },

    myHoverFunction() {
      var popup = document.getElementById("myPopup");
      popup.classList.toggle("show");
    },
  },
};
</script>
<style>
body {
  margin: 60px;
}
h1,
h2 {
  font-weight: normal;
}

.line {
  width: 100%;
  display: flex;
  flex-direction: row;
}

button.paypal {
  background-color: #00457c;
  font-weight: 600;
}

button.paypal:disabled {
  background-color: #e6e6e6;
}

button.card {
  background-color: black;
  color: #44a948;
  font-weight: 600;
}

.constrain {
  width: 480px;
  margin: 0 auto;
  padding: 1rem;
}

label {
  font-size: 16px;
  display: block;
  text-align: left;
  font-weight: bold;
  padding: 0.25rem;
  width: 100%;
}

.input-field {
  height: 50px;
  box-sizing: border-box;
  width: 100%;
  padding: 0.8rem;
  display: inline-block;
  box-shadow: none;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 6px;
  border: 1px solid #dddddd;
  line-height: 1.2;
  background: #fcfcfc;
  margin-top: 0.1rem;
  margin-bottom: 0.8rem;
  background: linear-gradient(to right, white 50%, #fcfcfc 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 300ms ease-in-out;
  font-size: 18px;
  text-align: left;
}
[data-braintree-id="methods-container"] {
  display: none;
}
[data-braintree-id="other-ways-to-pay"] {
  display: none;
}

@media (max-width: 600px) {
  .constrain {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }
  .constrain .line label {
    margin-left: 0px;
  }
}
</style>
