<template>
  <loader
    :object="styling.objectcolor"
    :color1="styling.animationcolor"
    :color2="styling.animationcolor2"
    :size="styling.size"
    :speed="styling.speed"
    :bg="styling.bgcolor"
    :objectbg="styling.objectbg"
    :opacity="styling.opacity"
    :name="selected"
    :disableScrolling="styling.disableScrolling"
  ></loader>
  <div class="container credit-card-info-section">
    <div class="col-12 col-md-8 mx-auto">
      <div class="card bg-light">
        <div class="card-header">Payment Information</div>
        <div class="card-body">
          <div
            id="apple-pay"
            class="apple-pay-button apple-pay-button-black"
            @click="applePayClick"
          ></div>
        </div>
      </div>
    </div>
    <br />
    <div class="alert alert-success mx-auto" v-if="nonce">
      Payment successful
    </div>
    <!-- @click.prevent="applePayClick" -->
    <!-- <div class="Payment-Method-Main">
      <h2>Reimbursement Method</h2>

      <p>
        We use Direct Deposit to ensure we get claims reimbursement to you
        quickly by depositing them directly into your bank account. Please
        provide your banking information below.
      </p>

      <div class="row">
        <div class="col-md-4">
          <label>Account Holder Name*</label>
        </div>
        <div class="col-md-6">
          <input
            type="text"
            class="form-control Input-FieldCH"
            placeholder
            v-model="quote.accountHolder"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label>Branch/Transit Number*</label>
        </div>
        <div class="col-md-3">
          <input
            type="text"
            class="form-control Input-FieldCH"
            placeholder
            v-model="quote.transitNumber"
            maxlength="5"
            minlength="5"
            @keypress="isNumber($event)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label>Bank/Institution Number*</label>
        </div>
        <div class="col-md-2">
          <input
            type="text"
            class="form-control Input-FieldCH"
            placeholder
            v-model="quote.institutionNumber"
            maxlength="3"
            minlength="3"
            @keypress="isNumber($event)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label>Account Number*</label>
        </div>
        <div class="col-md-4">
          <input
            type="text"
            class="form-control Input-FieldCH"
            placeholder
            v-model="quote.accountNumber"
            maxlength="12"
            minlength="12"
            @keypress="isNumber($event)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label>Use a Cheque to locate your banking information</label>
        </div>
        <div class="col-md-6">
          <input
            style="display: none"
            type="text"
            class="form-control Input-FieldCH"
            placeholder=""
            v-model="quote.checkInfo"
          />

          <img alt="Vue logo" src="../assets/cheque.png" class="img-fluid" />
        </div>
      </div>
    </div> -->
    <!-- 
    <div class="Payment-Method-Main">
      <h2>Consent</h2>
      <div class="col-md-12 Main-Bot-Sec paymenyt-info-check">
        <div class="form-check check-ext">
          <input
            type="checkbox"
            class="form-check-input"
            id="exampleCheck1"
            v-model="quote.consent1"
            @change="checkConsent1($event)"
          />
          <label
            class="form-check-label"
            for="exampleCheck1"
            style="padding: 0px"
          >
            I consent to use my personal information for the purposes of
            obtaining insurance and administering my policy.
            <a
              href="https://www.phidirect.com/privacy-policy"
              target="_blank"
              style="color: #6aa442; text-decoration: underline"
              >(Privacy policy)</a
            >*
          </label>
        </div>
      </div>

      <div class="col-md-12 Main-Bot-Sec paymenyt-info-check">
        <div class="form-check check-ext">
          <input
            type="checkbox"
            class="form-check-input"
            id="exampleCheck2"
            @change="checkConsent2($event)"
          />
          <label
            class="form-check-label"
            for="exampleCheck2"
            style="padding: 0px"
            >By checking the box on the enrollment path to accept paperless
            communications. I agree to the Consent to Use Electric Records, and
            agree to go paperless.*</label
          >
        </div>
      </div>
    </div> -->
    <div
      class="alert alert-success mx-auto"
      v-if="!error && nonce && success == true"
    >
      Thank you. One moment please.
    </div>
    <div class="alert alert-danger mx-auto" v-if="error">{{ error }}</div>
  </div>

  <!-- <button class="Plan-ConBtn" @click.prevent="payWithApplePay">
    COMPLETE PURCHASE
  </button> -->
</template>

<script>
import braintree from "braintree-web";
import { ApplePaySession } from "braintree-web/apple-pay";
import axios from "axios";
import { client as config } from "../config";

export default {
  props: {
    wrapperClass: {
      value: String,
    },
    type: {
      type: String,
      required: false,
      default: "buy",
      validator: function (value) {
        return (
          [
            "plain",
            "buy",
            "set-up",
            "donate",
            "check-out",
            "book",
            "subscribe",
          ].indexOf(value) !== -1
        );
      },
    },
    color: {
      type: String,
      required: false,
      default: "black",
      validator: function (value) {
        return ["black", "white"].indexOf(value) !== -1;
      },
    },
    withLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      session: null,
      hostedFieldInstance: false,
      success: false,
      nonce: "",
      expirationMonth: "",
      expirationYear: "",
      error: "",
      amount: 0,
      authToken: "",
      infos: {},
      petsInfo: [],
      customerinfo: {
        Address1: "",
        Address2: "",
        ContactMethodId: 60789,
        DateOfBirth: "",
        Email: "",
        FirstName: "",
        HomePhone: "",
        LastName: "",
        MobilePhone: "",
        Postcode: "",
        County: "",
        TownCity: "",
        TitleId: "",
        SecondaryEmail: "",
        SecondaryFirstName: "",
        SecondaryLastName: "",
        SecondaryHomePhone: "",
        SecondaryMobilePhone: "",
        SecondaryTitleId: "",
        DoNotEmail: true,
        DoNotPhone: true,
        DoNotPost: true,
        DoNotSms: true,
      },
      paymentInfo: {
        CreditCardToken: "",
        CreditCardType: "",
        CreditCardHolderName: "",
        PostalCode: "",
        CreditCardExpireDate: "",
        CreditCardNumberMasked: "",
        PaymentIntervalId: 4,
        PaymentMethodTypeId: 0,
        PaymentDay: 15,
        ReimbursementAccountNumber: "",
        ReimbursementBankAccountName: "",
        ReimbursementInstitutionNumber: "",
        ReimbursementSortCode: "",
        //TotalGrossPremium: "",
      },
      BirthDate: "",
      BreedId: 0,
      Gender: "",
      PetName: "",
      SpeciesId: "",
      vetId: 1026,
      vetName: "",
      petsInfos: [],
      quote: {
        postalCode: "",
        consent1: "",
        accountHolder: "",
        transitNumber: "",
        institutionNumber: "",
        accountNumber: "",
        checkInfo: "",
      },
      check2: "",
      PostalCodes: "",
      Emails: "",
      policyItem: [],
      policyDetailsItems: [],
      CoInsurance: 0,
      Excess: 0,
      OptionalCoverages: "",
      PolicyLimit: 0,
      ProductId: 0,
      pet1_id: "",
      pet1_name: "",
      pet1_species: "",
      pet2_id: "",
      pet2_name: "",
      pet2_species: "",
      pet3_id: "",
      pet3_name: "",
      pet3_species: "",
      pet4_id: "",
      pet4_name: "",
      pet4_species: "",
      pet5_id: "",
      pet5_name: "",
      pet5_species: "",
      objectId: 0,
      VoluntaryExcess: 0,
      SavedquoteKey: "",
      styling: {
        objectcolor: "#ff9633",
        animationcolor: "#ffffff",
        animationcolor2: "#17fd3d",
        objectbg: "#999793",
        opacity: 80,
        bgcolor: "#343a40",
        size: 5,
        speed: 2,
        disableScrolling: false,
      },
      currentDay: "",
      underwritingAnswerItems: [],
      underwritingAnswerItemss: [],
      underQuestions: [],
      SessionId: 0,
      PaymentMethodId: 0,
      petsDetailsInfo: [],
      AnswerText: "",
      AnswerId: 0,
      QuestionId: 0,
    };
  },

  async beforeMount() {
    let getPolicyValue = localStorage.getItem("PetPolicyList");
    let quoteInfos = JSON.parse(getPolicyValue);

    this.SessionId = quoteInfos.petInfo.sessionId;
    this.SavedquoteKey = quoteInfos.petInfo.savedQuoteKey;
    quoteInfos.petInfo.petPolicyList.forEach((quotes) => {
      quotes.policyList.forEach((policyDetails) => {
        if (policyDetails.selected === true) {
          this.policyItem.push(policyDetails);
        }
      });
    });

    let emailss = localStorage.getItem("Email");
    let email = JSON.parse(emailss);
    this.Emails = email;

    let paymentMethods = localStorage.getItem("PaymentMethod");
    let paymentMethod = JSON.parse(paymentMethods);

    if (paymentMethod === "Card") {
      this.PaymentMethodId = 193355;
    }
    if (paymentMethod === "Paypal") {
      this.PaymentMethodId = 193366;
    }
    if (paymentMethod === "Apple") {
      this.PaymentMethodId = 193365;
    }

    let amounts = localStorage.getItem("PaymentAmount");
    this.amount = JSON.parse(amounts);

    let quoteInfo = JSON.parse(getPolicyValue);
    quoteInfo.petInfo.petPolicyList.forEach((quotes) => {
      this.petsDetailsInfo.push(quotes);
    });

    if (localStorage.getItem("ObjectId") != null) {
      let objectIds = localStorage.getItem("ObjectId");
      let objectidss = JSON.parse(objectIds);
      this.objectsIds = objectidss;
    }

    this.petsDetailsInfo.forEach((ques) => {
      let questions = {
        AnswerText: "",
        AnswerId: 0,
        QuestionId: 0,
      };
      this.AnswerText = ques.questions[0].messageIfYes;
      this.AnswerId = parseInt(ques.questions[0].selected);
      this.QuestionId = ques.questions[0].questionID;

      questions.AnswerText = this.AnswerText;
      questions.AnswerId = this.AnswerId;
      questions.QuestionId = this.QuestionId;
      this.underwritingAnswerItems.push(questions);
    });

    this.petsDetailsInfo.forEach((ques) => {
      let questions = {
        AnswerText: "",
        AnswerId: 0,
        QuestionId: 0,
      };

      this.AnswerText = ques.questions[1].messageIfYes;
      this.AnswerId = parseInt(ques.questions[1].selected);
      this.QuestionId = ques.questions[1].questionID;

      questions.AnswerText = this.AnswerText;
      questions.AnswerId = this.AnswerId;
      questions.QuestionId = this.QuestionId;
      this.underwritingAnswerItemss.push(questions);
    });
    var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    this.currentDay = `${currentDate.getDate()}`;

    this.queValue = this.$cookies.get("hubspotutk");

    let postCode = localStorage.getItem("PostalCode");
    this.PostalCodes = JSON.parse(postCode);
  },

  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    checkConsent1(e) {
      if (e.target.checked) {
        this.quote.consent1 == "Consent1";
      } else {
        this.quote.consent1 = "";
      }
    },

    checkConsent2(ev) {
      if (ev.target.checked) {
        this.check2 = "Consent2";
      } else {
        this.check2 = "";
      }
    },

    payWithApplePay() {
      const checkBox1 = this.quote.consent1;
      const checkBox2 = this.check2;
      this.error = "";
      this.success = false;
      try {
        if (this.nonce === "" || this.nonce === null) {
          this.error = "Please complete your payment details.";
          return;
        }
        if (
          this.quote.accountHolder === "" ||
          this.quote.accountHolder === null
        ) {
          this.error = "Account holder name required.";
          return;
        }
        if (
          this.quote.transitNumber === "" ||
          this.quote.transitNumber === null
        ) {
          this.error = "Transit number required.";
          return;
        }
        if (
          this.quote.institutionNumber === "" ||
          this.quote.institutionNumber === null
        ) {
          this.error = "Institution number required.";
          return;
        }
        if (
          this.quote.accountNumber === "" ||
          this.quote.accountNumber === null
        ) {
          this.error = "Account number required.";
          return;
        }
        if (checkBox1 === "" || checkBox2 === "") {
          this.error =
            "You must agree to the consent checkboxes in order to complete your purchase";
          return;
        } else {
          if (this.error === "" && this.nonce != "") {
            this.success = true;
            this.loader();
            this.BuyPolicy(this.nonce);
          }
        }
      } catch (err) {
        console.error(err);
        this.error = err.message;
      }
    },

    async BuyPolicy(token) {
      try {
        //Customer Info
        let infoDetails = localStorage.getItem("InfoDetails");
        this.infos = JSON.parse(infoDetails);
        this.customerinfo.FirstName = this.infos.customerInfo.FirstName;
        this.customerinfo.LastName = this.infos.customerInfo.LastName;
        this.customerinfo.DateOfBirth = "1976-11-10T00:00:00";
        this.customerinfo.Email = this.Emails;
        this.customerinfo.Address1 = this.infos.customerInfo.Address1;
        this.customerinfo.Address2 = this.infos.customerInfo.Address2;
        this.customerinfo.ContactMethodId = 60789;
        this.customerinfo.MobilePhone = this.infos.customerInfo.MobilePhone;
        this.customerinfo.HomePhone = this.infos.customerInfo.HomePhone;
        this.customerinfo.County = this.infos.province;
        this.customerinfo.TownCity = this.infos.customerInfo.TownCity;
        this.customerinfo.Postcode = this.infos.customerInfo.Postcode;
        this.customerinfo.TitleId = 0;
        this.customerinfo.SecondaryEmail = this.infos.customerInfo.SecondaryEmail;
        this.customerinfo.SecondaryFirstName = this.infos.customerInfo.SecondaryFirstName;
        this.customerinfo.SecondaryLastName = this.infos.customerInfo.SecondaryLastName;
        this.customerinfo.SecondaryHomePhone = this.infos.customerInfo.SecondaryHomePhone;
        this.customerinfo.SecondaryMobilePhone = this.infos.customerInfo.SecondaryMobilePhone;
        this.customerinfo.SecondaryTitleId = 0;
        this.customerinfo.DoNotEmail = true;
        this.customerinfo.DoNotPhone = true;
        this.customerinfo.DoNotPost = true;
        this.customerinfo.DoNotSms = true;

        //Payment Info
        this.paymentInfo.CreditCardToken = token;
        this.paymentInfo.CreditCardHolderName = this.cardHolderName;
        this.paymentInfo.CreditCardType = this.cardType;
        this.paymentInfo.PaymentIntervalId = 4;
        this.paymentInfo.PostalCode = this.PostalCodes;
        this.paymentInfo.PaymentMethodTypeId = this.PaymentMethodId;
        this.paymentInfo.PaymentDay = parseInt(this.currentDay);
        this.paymentInfo.ReimbursementAccountNumber = this.quote.accountNumber;
        this.paymentInfo.ReimbursementBankAccountName = this.quote.accountHolder;
        this.paymentInfo.ReimbursementInstitutionNumber = this.quote.institutionNumber;
        this.paymentInfo.ReimbursementSortCode = this.quote.transitNumber;

        //Pets Info
        let getPolicyValue = localStorage.getItem("PetPolicyList");
        let quoteInfo = JSON.parse(getPolicyValue);
        quoteInfo.petInfo.petPolicyList.forEach((quotes) => {
          this.petsInfo.push(quotes.petInfo);
        });

        this.policyItem.forEach((policys) => {
          let policy = {
            CoInsurance: 0,
            Excess: 0,
            OptionalCoverages: "",
            PolicyLimit: 0,
            ProductId: 0,
            VoluntaryExcess: 0,
          };
          this.CoInsurance = policys.coInsurance.selected;
          this.Excess = policys.excess.selected;
          this.OptionalCoverages = policys.optionalCoverages;
          this.PolicyLimit = policys.policyLimit.selected;
          this.ProductId = policys.productId;
          this.VoluntaryExcess = policys.voluntaryExcess.selected;

          policy.CoInsurance = this.CoInsurance;
          policy.Excess = this.Excess;
          policy.OptionalCoverages = this.OptionalCoverages;
          policy.PolicyLimit = this.PolicyLimit;
          policy.ProductId = this.ProductId;
          policy.VoluntaryExcess = this.VoluntaryExcess;
          this.policyDetailsItems.push(policy);
        });

        this.petsInfo.forEach((pets, index) => {
          let petinfo = {
            BirthDate: "",
            BreedId: "",
            Gender: "",
            PetName: "",
            SpeciesId: "",
            VetId: parseInt(this.petsDetailsInfo[index].questions[2].Id),
            VetName: this.petsDetailsInfo[index].questions[2].VetName,
            UnderwritingAnswerItemTypes: [
              this.underwritingAnswerItems[index],
              this.underwritingAnswerItemss[index],
            ],
            PolicyValues: [this.policyDetailsItems[index]],
          };

          this.BirthDate = pets.birthDate;
          this.BreedId = pets.breedId;
          this.Gender = pets.gender;
          this.PetName = pets.petName;
          this.SpeciesId = pets.speciesId;

          petinfo.BirthDate = this.BirthDate;
          petinfo.BreedId = this.BreedId;
          petinfo.Gender = this.Gender;
          petinfo.PetName = this.PetName;
          petinfo.SpeciesId = this.SpeciesId;
          this.petsInfos.push(petinfo);
        });

        let petdetails = JSON.stringify(this.petsInfos);
        localStorage.setItem("PetDetails", petdetails);

        getPolicyValue = localStorage.getItem("PetPolicyList");
        let pet = JSON.parse(getPolicyValue);

        if (pet.petInfo.petPolicyList.length <= 5) {
          if (pet.petInfo.petPolicyList.length >= 1) {
            let petRefs1 = pet.petInfo.petPolicyList[0].petInfo.petRef
              ? pet.petInfo.petPolicyList[0].petInfo.petRef
              : "";
            this.pet1_id = petRefs1;
            let petName1 = pet.petInfo.petPolicyList[0].petInfo.petName
              ? pet.petInfo.petPolicyList[0].petInfo.petName
              : "";
            this.pet1_name = petName1;
            let speciesId1 = pet.petInfo.petPolicyList[0].petInfo.speciesId
              ? pet.petInfo.petPolicyList[0].petInfo.speciesId
              : "";
            this.pet1_species = speciesId1;
          }

          if (pet.petInfo.petPolicyList.length >= 2) {
            let petRefs2 = pet.petInfo.petPolicyList[1].petInfo.petRef
              ? pet.petInfo.petPolicyList[1].petInfo.petRef
              : "";
            this.pet2_id = petRefs2;
            let petName2 = pet.petInfo.petPolicyList[1].petInfo.petName
              ? pet.petInfo.petPolicyList[1].petInfo.petName
              : "";
            this.pet2_name = petName2;
            let speciesId2 = pet.petInfo.petPolicyList[1].petInfo.speciesId
              ? pet.petInfo.petPolicyList[1].petInfo.speciesId
              : "";
            this.pet2_species = speciesId2;
          }

          if (pet.petInfo.petPolicyList.length >= 3) {
            let petRefs3 = pet.petInfo.petPolicyList[2].petInfo.petRef
              ? pet.petInfo.petPolicyList[2].petInfo.petRef
              : "";
            this.pet3_id = petRefs3;
            let petName3 = pet.petInfo.petPolicyList[2].petInfo.petName
              ? pet.petInfo.petPolicyList[2].petInfo.petName
              : "";
            this.pet3_name = petName3;
            let speciesId3 = pet.petInfo.petPolicyList[2].petInfo.speciesId
              ? pet.petInfo.petPolicyList[2].petInfo.speciesId
              : "";
            this.pet3_species = speciesId3;
          }

          if (pet.petInfo.petPolicyList.length >= 4) {
            let petRefs4 = pet.petInfo.petPolicyList[3].petInfo.petRef
              ? pet.petInfo.petPolicyList[3].petInfo.petRef
              : "";
            this.pet4_id = petRefs4;
            let petName4 = pet.petInfo.petPolicyList[3].petInfo.petName
              ? pet.petInfo.petPolicyList[3].petInfo.petName
              : "";
            this.pet4_name = petName4;
            let speciesId4 = pet.petInfo.petPolicyList[3].petInfo.speciesId
              ? pet.petInfo.petPolicyList[3].petInfo.speciesId
              : "";
            this.pet4_species = speciesId4;
          }

          if (pet.petInfo.petPolicyList.length >= 5) {
            let petRefs5 = pet.petInfo.petPolicyList[4].petInfo.petRef
              ? pet.petInfo.petPolicyList[4].petInfo.petRef
              : "";
            this.pet5_id = petRefs5;
            let petName5 = pet.petInfo.petPolicyList[4].petInfo.petName
              ? pet.petInfo.petPolicyList[4].petInfo.petName
              : "";
            this.pet5_name = petName5;
            let speciesId5 = pet.petInfo.petPolicyList[4].petInfo.speciesId
              ? pet.petInfo.petPolicyList[4].petInfo.speciesId
              : "";
            this.pet5_species = speciesId5;
          }

          if (this.objectsIds === null) {
            this.objectsIds = pet.petInfo.responseId;
          }
          //Activity
          let Postmodel = {
            CustomerInfo: this.customerinfo,
            PaymentInfo: this.paymentInfo,
            PetsInfo: this.petsInfos,
            SessionId: this.SessionId,
            SavedQuoteKey: this.SavedquoteKey,
            pet1_id: this.pet1_id,
            pet1_name: this.pet1_name,
            pet1_species: this.pet1_species.toString(),
            pet2_id: this.pet2_id,
            pet2_name: this.pet2_name,
            pet2_species: this.pet2_species.toString(),
            pet3_id: this.pet3_id,
            pet3_name: this.pet3_name,
            pet3_species: this.pet3_species.toString(),
            pet4_id: this.pet4_id,
            pet4_name: this.pet4_name,
            pet4_species: this.pet4_species.toString(),
            pet5_id: this.pet5_id,
            pet5_name: this.pet5_name,
            pet5_species: this.pet5_species.toString(),
            objectId: parseInt(this.objectsIds),
            cookieValue: this.queValue,
          };
          let url = config.api.policyUrl + "BuyPolicy";
          let response = await axios.post(url, Postmodel);
          // debugger;
          if (response.data.responseId === null) {
            if (response.data.result === true) {
              let policyNumber = JSON.stringify(response.data);
              localStorage.setItem("BuyPolicyData", policyNumber);
              localStorage.removeItem("InfoDetails");
              localStorage.removeItem("PetPolicyList");
              localStorage.removeItem("PostalCode");
              localStorage.removeItem("ObjectId");
              this.$router.push({ name: "welcome" });
            }
          } else if (
            response.data.responseId ==
            "Email address required. Please enter your email address here to continue to complete your purchase"
          ) {
            response.data.responseId =
              "Email address required. Please enter your email address here to continue to complete your purchase";

            this.$toast(response.data.responseId, {
              styles: {
                background: "Coral",
              },
              type: "error",
              positionY: "top",
            });
            setTimeout(() => {
              window.location.href = "/";
            }, 4000);
          } else {
            this.$toast(response.data.responseId, {
              styles: {
                background: "Coral",
              },
              type: "error",
              positionY: "top",
            });
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async applePayClick() {
      if (!window.ApplePaySession) {
        this.$toast("This device does not support Apple Pay", {
          styles: {
            background: "Coral",
          },
          type: "error",
          positionY: "top",
        });
        return;
      }

      if (!window.ApplePaySession.canMakePayments()) {
        this.$toast("This device is not capable of making Apple Pay payments", {
          styles: {
            background: "Coral",
          },
          type: "error",
          positionY: "top",
        });
        return;
      }
      var applePay = require("braintree-web/apple-pay");
      var self = this;
      braintree.client.create(
        {
          authorization: self.authToken,
        },
        function (clientErr, clientInstance) {
          if (clientErr) {
            console.error("Error creating client:", clientErr);
            return;
          }
          applePay.create(
            {
              client: clientInstance,
            },
            function (applePayErr, applePayInstance) {
              if (applePayErr) {
                console.error("Error creating applePayInstance:", applePayErr);
                return;
              }
              document
                .getElementById("apple-pay")
                .addEventListener("click", function () {
                  let amounts = localStorage.getItem("PaymentAmount");
                  this.amount = JSON.parse(amounts);
                  // Set up your Apple Pay button here
                  var paymentRequest = applePayInstance.createPaymentRequest({
                    total: {
                      label: "PHI Direct",
                      amount: parseFloat(this.amount),
                      type: "final",
                      currency: "USD",
                    },
                    // We recommend collecting billing address information, at minimum
                    // billing postal code, and passing that billing postal code with
                    // all Apple Pay transactions as a best practice.
                    // requiredShippingContactFields: ["postalAddress"],
                    requiredBillingContactFields: ["postalAddress"],
                    supportedNetworks: [
                      "visa",
                      "mastercard",
                      "amex",
                      "discover",
                    ],
                  });

                  console.log(paymentRequest.countryCode);
                  console.log(paymentRequest.currencyCode);
                  console.log(paymentRequest.merchantCapabilities);
                  console.log(paymentRequest.supportedNetworks);

                  var session = new window.ApplePaySession(3, paymentRequest);

                  session.onvalidatemerchant = function (event) {
                    console.log("onvalidatemerchant", event);
                    applePayInstance.performValidation(
                      {
                        validationURL: event.validationURL,
                        displayName: "PHI Direct",
                      },
                      function (err, merchantSession) {
                        if (err) {
                          // You should show an error to the user, e.g. 'Apple Pay failed to load.'
                          return;
                        }
                        session.completeMerchantValidation(merchantSession);
                      }
                    );
                  };

                  session.onpaymentauthorized = function (event) {
                    console.log("onpaymentauthorized", event);
                    console.log("payment", JSON.stringify(event.payment));
                    console.log("Your shipping address is:", event.payment);

                    applePayInstance.tokenize(
                      {
                        token: event.payment.token,
                      },
                      function (tokenizeErr, payload) {
                        if (tokenizeErr) {
                          console.error(
                            "Error tokenizing Apple Pay:",
                            tokenizeErr
                          );
                          session.completePayment(
                            ApplePaySession.STATUS_FAILURE
                          );
                          return;
                        }

                        // Send payload.nonce to your server.
                        console.log("nonce:", payload.nonce);
                        localStorage.setItem("Applenonce", payload.nonce);
                        self.nonce = payload.nonce;
                        self.cardType = payload.details.cardType;
                        self.cardHolderName = payload.details.cardholderName;
                        // If requested, address information is accessible in event.payment
                        // and may also be sent to your server.
                        console.log(
                          "billingPostalCode:",
                          event.payment.billingContact.postalCode
                        );

                        // After you have transacted with the payload.nonce,
                        // call `completePayment` to dismiss the Apple Pay sheet.
                        session.completePayment(ApplePaySession.STATUS_SUCCESS);
                      }
                    );
                  };
                  session.begin();
                });
            }
          );
        }
      );
    },

    loader() {
      this.selected = "circular";
      this.code = `<loader object="${this.styling.objectcolor}" color1="${this.styling.animationcolor}" color2="${this.styling.animationcolor2}" size="${this.styling.size}" speed="${this.styling.speed}" bg="${this.styling.bgcolor}" objectbg="${this.styling.objectbg}" opacity="${this.styling.opacity}" disableScrolling="${this.styling.disableScrolling}" name="${this.selected}"></loader>`;
      setTimeout(() => {
        this.selected = "";
      }, 2000);
    },
  },

  async mounted() {
    if (!window.ApplePaySession) {
      this.$toast("This device does not support Apple Pay", {
        styles: {
          background: "Coral",
        },
        type: "error",
        positionY: "top",
      });
      return;
    }

    if (!window.ApplePaySession.canMakePayments()) {
      this.$toast("This device is not capable of making Apple Pay payments", {
        styles: {
          background: "Coral",
        },
        type: "error",
        positionY: "top",
      });
      return;
    }
    let url = config.api.generateTokenUrl + "GenerateToken";
    const response = await axios.post(url);
    let token = response.data;
    this.authToken = token;
    var applePay = require("braintree-web/apple-pay");
    var self = this;
    braintree.client.create(
      {
        authorization: this.authToken,
      },
      function (clientErr, clientInstance) {
        if (clientErr) {
          console.error("Error creating client:", clientErr);
          return;
        }

        applePay.create(
          {
            client: clientInstance,
          },
          function (applePayErr, applePayInstance) {
            if (applePayErr) {
              console.error("Error creating applePayInstance:", applePayErr);
              return;
            }
            document
              .getElementById("apple-pay")
              .addEventListener("click", function () {
                let amounts = localStorage.getItem("PaymentAmount");
                this.amount = JSON.parse(amounts);
                // Set up your Apple Pay button here
                var paymentRequest = applePayInstance.createPaymentRequest({
                  total: {
                    label: "PHI Direct",
                    amount: parseFloat(this.amount),
                    type: "final",
                    currency: "USD",
                  },
                  // We recommend collecting billing address information, at minimum
                  // billing postal code, and passing that billing postal code with
                  // all Apple Pay transactions as a best practice.
                  // requiredShippingContactFields: ["postalAddress"],
                  requiredBillingContactFields: ["postalAddress"],
                  supportedNetworks: ["visa", "mastercard", "amex", "discover"],
                });

                console.log(paymentRequest.countryCode);
                console.log(paymentRequest.currencyCode);
                console.log(paymentRequest.merchantCapabilities);
                console.log(paymentRequest.supportedNetworks);

                var session = new window.ApplePaySession(3, paymentRequest);

                session.onvalidatemerchant = function (event) {
                  console.log("onvalidatemerchant", event);
                  applePayInstance.performValidation(
                    {
                      validationURL: event.validationURL,
                      displayName: "PHI Direct",
                    },
                    function (err, merchantSession) {
                      if (err) {
                        // You should show an error to the user, e.g. 'Apple Pay failed to load.'
                        return;
                      }
                      session.completeMerchantValidation(merchantSession);
                    }
                  );
                };

                session.onpaymentauthorized = function (event) {
                  console.log("onpaymentauthorized", event);
                  console.log("payment", JSON.stringify(event.payment));
                  console.log("Your shipping address is:", event.payment);
                  applePayInstance.tokenize(
                    {
                      token: event.payment.token,
                    },
                    function (tokenizeErr, payload) {
                      if (tokenizeErr) {
                        console.error(
                          "Error tokenizing Apple Pay:",
                          tokenizeErr
                        );
                        // session.completePayment(ApplePaySession.STATUS_FAILURE);
                        session.completePayment({
                          status: window.ApplePaySession.STATUS_FAILURE,
                        });
                        return;
                      }

                      // Send payload.nonce to your server.
                      console.log("nonce:", payload.nonce);
                      self.nonce = payload.nonce;
                      localStorage.setItem("Applenonce", payload.nonce);
                      self.cardType = payload.details.cardType;
                      self.cardHolderName = payload.details.cardholderName;
                      // If requested, address information is accessible in event.payment
                      // and may also be sent to your server.
                      console.log(
                        "billingPostalCode:",
                        event.payment.billingContact.postalCode
                      );

                      // After you have transacted with the payload.nonce,
                      // call `completePayment` to dismiss the Apple Pay sheet.
                      // session.completePayment(ApplePaySession.STATUS_SUCCESS);
                      session.completePayment({
                        status: window.ApplePaySession.STATUS_SUCCESS,
                      });
                    }
                  );
                };
                session.begin();
              });
          }
        );
      }
    );
  },
};
</script>

<style>
.apple-pay-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  /* -apple-pay-button-type: buy; Use any supported button type. */
  cursor: pointer;
}
.apple-pay-button-black {
  -apple-pay-button-style: black;
}
.apple-pay-button-white {
  -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
  /* -apple-pay-button-style: light-gray background; */
}
.apple-pay-button-white-with-line {
  background-color: lightgray;
}
</style>
